/* eslint-disable complexity */
import React, { Component } from 'react';
import TripStatsHeader from './TripStatsHeader';
import { bindActionCreators } from 'redux';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';
import NoPrivilege from './NoPrivilege';
import FeatureNotAvailable from './FeatureNotAvailable';
import './tripStats.scss';
import axios from 'axios';
import { isNonEmptyObject } from '../../../util/utilityMethods';
import TripWiseAndTripsNotEnrolled from './TripWiseAndTripsNotEnrolled';
import { getLocaleSync } from '../../../providers/languageProvider';
import DriveViewHome from '../driveView/driveViewHome';
import { getTodaysDate, getEpochDayStartDate, getEpochDayEndDate } from '../common/commonDateUtil';
import noDriveViewScores from '../../../assets/driveView/driver-score-icon.png';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import { privilegeCheck, isUBIAvailable, areTripsAvailable, ubiPausedMsgAvailable } from './TripStatsUtil';
import EnrollTripWise from './EnrollTripWise';
import { isUserPrimary } from '../../../providers/userRolesProvider';
import { setTitle } from '../../../providers/documentTitleProvider';
import UbiPausedMsg from './ubiPausedMsg';

class TripStatsHome extends Component {
  constructor () {
    super();
    this.state = {
      driveViewInfo: '',
      vehicleDetails: '',
      showUBI: false,
      showRTS: true,
      RenderTripWise: false,
      vehicleTripsFeatures: '',
      UBIAvailable: false,
      tripsAvailable: false,
      geicoMsgIsAvailable: false
    };
    this.tripsPrivilege = '';
    this.userCountry = '';
    this.translator = getLocaleTranslator();
    this.userPrimaryStatus = '';
    this.getDriveViewDetailsPromise = null;
  }

  componentDidMount () {
    this.props.actions.getCarnetUserPrivileges();
    this.getVehicleDetails();
    this.getVehicleTripsFeatureGroup();
    let locale = getLocaleSync();
    this.userCountry = locale ? locale.userCountry.toUpperCase() : 'US';
    this.userPrimaryStatus = isUserPrimary();
  }

  getDriveViewXhrPromise = (startDate, endDate) => {
    if (this.getDriveViewDetailsPromise) {
      return this.getDriveViewDetailsPromise;
    }
    this.getDriveViewDetailsPromise = axios
      .get(`tripwise/fork?type=daily&startDate=${startDate}&endDate=${endDate}`)
      .then(res => {
        this.setState({
          driveViewInfo: res.data.data
        });
      });
  };

  getDriveViewDetails = () => {
    if (this.state.driveViewInfo) {
      return;
    }
    let startDate = getEpochDayStartDate(getTodaysDate());
    let endDate = getEpochDayEndDate(getTodaysDate());
    this.getDriveViewXhrPromise(startDate, endDate);
  };

  getPrivilege = () => {
    let tsService;
    let privileges;
    let userPrivilegesData = this.props.userPrivilegesData;

    if (isNonEmptyObject(userPrivilegesData)) {
      tsService = userPrivilegesData.services.filter(service => service.shortCode === 'TS')[0] || {};
    }

    if (isNonEmptyObject(tsService) && isNonEmptyObject(tsService.operations)) {
      privileges = tsService.operations[0].privilege;
    }
    this.tripsPrivilege = privilegeCheck(privileges);
  };

  getVehicleDetails = () => {
    axios.get(`/vehicle`).then(res => {
      this.setState({ vehicleDetails: res.data.data });
    });
  };

  // CW37 vehicle Fix
  // Invoke the route to get the trips feature group to determine whether UBI or Trips available
  getVehicleTripsFeatureGroup = () => {
    axios.get(`/vehicle/trips/featuregroup`).then(res => {
      this.setState({ vehicleTripsFeatures: res.data.data });
      this.setState({
        UBIAvailable: this.state.vehicleTripsFeatures && isUBIAvailable(this.state.vehicleTripsFeatures.features)
      });
      this.setState({
        tripsAvailable: this.state.vehicleTripsFeatures && areTripsAvailable(this.state.vehicleTripsFeatures.features)
      });
      this.setState({
        ubiPausedMsgAvailable: ubiPausedMsgAvailable(this.state.vehicleTripsFeatures.features)
      });
    });
  };

  //End of  CW37 Vehicle fix

  CallDriveView = () => {
    this.setState({ RenderTripWise: true });
  };

  render () {
    let vin = this.state.vehicleDetails && this.state.vehicleDetails.vin;
    let userCountryIsUS = this.userCountry === 'US';
    setTitle('document_titles.trips');
    if (this.state.RenderTripWise) {
      return <EnrollTripWise vin={vin} />;
    } else {
      let includePage;
      this.getPrivilege();
      let userRole = this.props.userPrivilegesData && this.props.userPrivilegesData.roles;
      let tripWiseEnrolled = this.state.driveViewInfo && this.state.driveViewInfo.tripWiseEnrolled;
      let tripStatsEnrolled = this.state.driveViewInfo && this.state.driveViewInfo.tripStatsEnrolled;
      let snapShot = this.state.driveViewInfo && this.state.driveViewInfo.snapShot;

      includePage = this.userAccessibilityCheck(
        tripWiseEnrolled,
        userCountryIsUS,
        tripStatsEnrolled,
        userRole,
        vin,
        snapShot
      );

      return (
        <div role='main' id='main-content'>
          {userCountryIsUS && (
            <UbiPausedMsg available={this.state.ubiPausedMsgAvailable} translator={this.translator} />
          )}
          {includePage}
        </div>
      );
    }
  }

  DriveViewNotEnrolled = () => {
    return (
      <div>
        <div id='driveViewNotEnrolled' className='driveViewNotEnrolled'>
          <img src={noDriveViewScores} alt={this.translator.t('trips.driveview_score_icon')} />
          {this.userPrimaryStatus ? (
            <div className='rts-ubinotenrolled-txt'>{this.translator.t('driveview.alerts.not-enrolled-text')}</div>
          ) : (
            <div className='rts-ubinotenrolled-txt'>
              {this.translator.t('trips.secoundaryuser_driveview_notenrolled_txt')}
            </div>
          )}

          {this.userPrimaryStatus && (
            <button className='cwp mb-5' onClick={() => this.CallDriveView()}>
              {this.translator.t('driveview.label.enroll-now')}
            </button>
          )}
        </div>
      </div>
    );
  };

  // ubiPausedMsgAvailable should be displayed if noDriveViewScore is available or score is zero
  setUbiPausedIfNotSet = () => {
    if (!this.state.ubiPausedMsgAvailable) {
      this.setState({
        ubiPausedMsgAvailable: true
      });
    }
  };

  userAccessibilityCheck = (tripWiseEnrolled, userCountryIsUS, tripStatsEnrolled, userRole, vin, snapShot) => {
    let includePage;
    // As mentioned in walkthrough  RRS has some limitations due to which this new logic is Added for CW37 or any other vehicle that will not have Trips or UBI availability.
    if (this.state.UBIAvailable || this.state.tripsAvailable) {
      // As mentioned in walkthrough retaining the previous logic as it is if driveview or Trips available execute the same logic
      if (this.tripsPrivilege) {
        this.getDriveViewDetails();
        if (tripWiseEnrolled && userCountryIsUS) {
          if (snapShot) {
            includePage = (
              <DriveViewHome tripStatsEnrolled={tripStatsEnrolled} setUbiPausedIfNotSet={this.setUbiPausedIfNotSet} />
            );
          } else if (!snapShot && tripStatsEnrolled) {
            includePage = this.tripStatsEnable(userRole, vin);
          } else {
            includePage = this.noDriveViewScore();
            // ubiPausedMsgAvailable should be displayed if noDriveViewScore is available
            this.state.ubiPausedMsgAvailable && this.setUbiPausedIfNotSet();
          }
        } else if (tripStatsEnrolled) {
          includePage = this.tripStatsEnable(userRole, vin);
        } else if (!tripWiseEnrolled && userCountryIsUS) {
          includePage = this.DriveViewNotEnrolled();
        } else if (!tripWiseEnrolled && !tripStatsEnrolled) {
          includePage = this.tripWiseAndTripsNotEnrolled(userRole);
        }
      } else if (!this.tripsPrivilege) {
        includePage = this.noUserPrivilege();
      }
    } else {
      // Based on Vehicle feature group API for Tprips if UBI or Trips not available display below message.
      includePage = this.vehiclefeatureNotAvailable();
    }
    return includePage;
  };

  noUserPrivilege = () => {
    return (
      <div className='rts-home'>
        <NoPrivilege />
      </div>
    );
  };

  vehiclefeatureNotAvailable = () => {
    return (
      <div className='rts-home'>
        <FeatureNotAvailable />
      </div>
    );
  };

  tripWiseAndTripsNotEnrolled = userRole => {
    return (
      <div className='rts-home'>
        <TripWiseAndTripsNotEnrolled userRole={userRole} />
      </div>
    );
  };

  tripStatsEnable = (userRole, vin) => {
    return (
      <div className='rts-home'>
        <TripStatsHeader userRole={userRole} userCountry={this.userCountry} vin={vin} locale={this.props.locale} />
      </div>
    );
  };

  noDriveViewScore = () => {
    return (
      <div>
        <div className={`${this.state.geicoMsgIsAvailable}? rts-no-score no-padding: rts-no-score`}>
          {this.translator.t('driveview.alerts.no-offers-insurance-messages-text')}
          <br />
          <img src={noDriveViewScores} alt={this.translator.t('trips.driveview_score_icon')} />
          <br />
          <br />
        </div>
      </div>
    );
  };
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getCarnetUserPrivileges: actions.getCarnetUserPrivileges
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  const { userPrivilegesDetails } = state;
  return {
    userPrivilegesData: userPrivilegesDetails.userPrivilegesData
  };
};

export { TripStatsHome };
export default connect(mapStateToProps, mapDispatchToProps)(TripStatsHome);
