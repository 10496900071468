import { createLogic } from 'redux-logic';
import axios from 'axios';
import {
  GET_CARNET_CUSTOMER_STATIC_DATA,
  GET_CARNET_CUSTOMER_STATIC_DATA_SUCCESS,
  GET_CARNET_CUSTOMER_STATIC_DATA_FAILURE
} from '../../actions/types';

let dataProvider = axios;

let requestConfig = {
  method: 'GET',
  url: `customer/static`,
  headers: {
    accept: 'application/json'
  }
};

const getCarnetCustomerStaticDataLogic = createLogic({
  type: GET_CARNET_CUSTOMER_STATIC_DATA,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: GET_CARNET_CUSTOMER_STATIC_DATA_SUCCESS,
    failType: GET_CARNET_CUSTOMER_STATIC_DATA_FAILURE
  },

  process ({ action }) {
    console.log('getCarnetCustomerStaticDataLogic is processing an action >>>' + action);

    return dataProvider(requestConfig)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error('Error in getCarnetCustomerStaticDataLogic:', error);
        throw error;
      });
  }
});

export default [getCarnetCustomerStaticDataLogic];
