import {
  GET_CUSTOMER_ACCOUNT_DETAILS,
  GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS,
  GET_CUSTOMER_ACCOUNT_DETAILS_FAILURE,
  UPDATE_CUSTOMER_DETAILS_SUCCESS
} from '../../actions/types';

const initialState = {
  customerData: null,
  dataLoading: false
};

const customerAccountDetailsReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const responseData = payload.data || {};

  switch (type) {
    case GET_CUSTOMER_ACCOUNT_DETAILS:
      return {
        ...state,
        dataLoading: true
      };

    case GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        customerData: responseData.data || null,
        dataLoading: false
      };

    case UPDATE_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          customer: payload.data || state.customerData.customer
        }
      };

    case GET_CUSTOMER_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        customerData: null,
        dataLoading: false
      };

    default:
      return state;
  }
};

export default customerAccountDetailsReducer;
