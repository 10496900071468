import React from 'react';
import { Parser as HtmlToReactParser } from 'html-to-react';
import { getLocaleTranslator, findErrorMessage } from '../../../util/i18n/i18nService';
import { UncontrolledAlert, Modal, ModalBody } from 'reactstrap';
import {
  TOS_TYPE,
  findApplicableTos,
  submitTosCarnetUBI,
  TOS_STATUS,
  submitUBIDataShareTos
} from '../providers/SelfEnrollmentProvider';
import carnet_constants from '../../../constants/carnet_constants';
import { getEnrollmentTosDetails } from '../../carnet/providers/EnrollmentTosProvider';

class TosUBI extends React.Component {
  state = {
    alert: null,
    processing: false,
    isOpenTosContentModal: false,
    tosVersion: null,
    tosStatus: null,
    tosContent: null,
    dataShareTosStatus: null,
    dataShareTosVersion: null,
    dataShareTosExists: null
  };

  constructor (props) {
    super(props);

    this.translator = getLocaleTranslator();
  }

  fetchTosContent = () => {
    const parser = new HtmlToReactParser();
    return parser.parse(this.state.tosContent);
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.setState({ processing: true });
    try {
      let { tosVersion, tosContent } = await findApplicableTos(TOS_TYPE.UBI);
      const response = await findApplicableTos(TOS_TYPE.UBI_DATA_SHARE);
      const tosDetailsResp = await getEnrollmentTosDetails();
      const { eligibleToses } = tosDetailsResp?.data || {};

      this.setState({
        tosVersion,
        tosContent,
        processing: false,
        dataShareTosVersion: response?.tosVersion,
        dataShareTosExists: eligibleToses.includes(carnet_constants.UBI_DATA_SHARE)
      });
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  // helper function to run on rendered html
  // need to hijack <anchor></anchor> tags because of react-router
  parseAndReplaceAnchors = () => {
    setTimeout(() => {
      let el = document.getElementById('tosBodyWrapper');
      let anchors = (el && el.querySelectorAll('a')) || [];
      anchors.forEach(element => {
        let href = element.getAttribute('href');
        let isHash = href && String(href).includes('#');
        let partsArr = isHash && String(href).split('#');
        let divId = null;
        if (Array.isArray(partsArr) && partsArr[0] === '' && partsArr[1]) {
          divId = partsArr[1];
        }
        let div = divId ? document.getElementById(divId) : null;
        if (divId && div) {
          element.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
            div.scrollIntoView();
          });
          if (isHash) {
            element.setAttribute('href', '#');
          }
        }
      });
    }, 250);
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  setTosStatus = tosStatus => {
    this.setState({ tosStatus });
  };

  submitTos = async () => {
    this.setState({ processing: true, alert: null });

    try {
      const response = await submitTosCarnetUBI(this.state.tosVersion, this.state.tosStatus);
      if (this.state.dataShareTosStatus) {
        await submitUBIDataShareTos(this.state.dataShareTosStatus, this.state.dataShareTosVersion);
      }

      this.setState({ processing: false });

      this.props.afterSubmit(this.state.tosStatus, response);
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  toggleTosContentModal = () => {
    this.setState({ isOpenTosContentModal: !this.state.isOpenTosContentModal });
  };

  render () {
    return (
      <>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-10 text-center mx-auto mb-5'>
            <h2 className='font-weight-normal'>{this.translator.t('tos_ubi.enroll')}</h2>
          </div>
          <div className='col-md-10 text-center'>
            <h4 className='font-weight-normal'>{this.translator.t('tos_ubi.drive_view')}</h4>
          </div>
          <div className='col-md-10 text-left mx-auto mb-5'>
            {this.getAlerts()}
            <br />
            <div className='row m-0 p-0'>
              <div className='col-md-4 px-0'>
                <div className='ubiPhoneScreen'></div>
              </div>
              <div className='col-md-8'>
                <h5 className='mt-5'>{this.translator.t('tos_ubi.learn_about_your_driving_behavior')}</h5>
                <span className='small'>{this.translator.t('tos_ubi.learn_about_your_driving_behavior_desc')}</span>
                <div className='row mx-0 my-md-3'>
                  <div className='col-md-6 p-0'>
                    <h6 className='leftIcon iconBreak'>{this.translator.t('tos_ubi.hard_braking')}</h6>
                    <spam className='small'>{this.translator.t('tos_ubi.hard_braking_desc')}</spam>
                    <br />
                    <h6 className='leftIcon iconSpeed'>{this.translator.t('tos_ubi.high_speed_driving')}</h6>
                    <spam className='small'>{this.translator.t('tos_ubi.high_speed_driving_desc')}</spam>
                  </div>
                  <div className='col-md-6 p-0'>
                    <h6 className='leftIcon iconNighttime'>{this.translator.t('tos_ubi.nighttime_driving')}</h6>
                    <spam className='small'>{this.translator.t('tos_ubi.nighttime_driving_desc')}</spam>
                    <br />
                    <h6 className='leftIcon iconIdle'>{this.translator.t('tos_ubi.idle_time')}</h6>
                    <spam className='small'>{this.translator.t('tos_ubi.idle_time_desc')}</spam>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-1'></div>
        </div>
        <div className='row ubiHilights m-0'>
          <div className='col-md-1'></div>
          <div className='col-md-10 text-left mx-auto my-5'>
            <div className='row m-0 p-0'>
              <div className='col-md-4 px-0'></div>
              <div className='col-md-8'>
                <div className='col-md-8 m-0 p-0'>
                  <h2 className='font-weight-normal my-0'>{this.translator.t('tos_ubi.why_should_share_my')}</h2>
                  <span className='small'>{this.translator.t('tos_ubi.driveview_calculates_cumulative')}</span>
                  <br />
                  <br />
                  <span className='small'>{this.translator.t('tos_ubi.you_can_still_use_driveview')}</span>
                  <div className='discountedInsuranceRates mt-4'>
                    {this.translator.t('tos_ubi.discounted_insurance_rates_may_not_be_available_in_all_states')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-1'></div>
        </div>

        <div className='col-md-8 text-left mx-auto my-5'>
          <br />
          <br />
          <h5>{this.translator.t('tos_ubi.would_you_like_to_enroll_drive_view')}</h5>
          <span
            className={TOS_STATUS.ACCEPT === this.state.tosStatus ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
            onClick={() => this.setTosStatus(TOS_STATUS.ACCEPT)}
          >
            <span className='radioDescText'>{this.translator.t('tos_ubi.enroll_me_in_DriveView')}</span>
          </span>
          <br />
          <span>
            <p className='ml-5'>
              {this.translator.t('tos_ubi.enroll_me_in_DriveView_desc_1')}
              <span className='carnetLink' onClick={this.toggleTosContentModal}>
                {this.translator.t('tos_ubi.driveview_tos')}
              </span>
              {this.translator.t('tos_ubi.and')}
              <a href='https://www.vw.com/privacy/' target='blank' className='carnetLink'>
                {this.translator.t('tos_ubi.vw_privacy_statement')}.
              </a>
            </p>
          </span>
          <span
            className={TOS_STATUS.DECLINE === this.state.tosStatus ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
            onClick={() => this.setTosStatus(TOS_STATUS.DECLINE)}
          >
            <span className='radioDescText'>{this.translator.t('tos_ubi.i_do_not_wish_to_enroll_in_driveview')} </span>
          </span>
        </div>

        {this.state.dataShareTosExists && (
          <div
            className={`col-md-8 text-left mx-auto my-5 ${
              this.state.tosStatus === TOS_STATUS.DECLINE ? 'disableDataShare' : ''
            }`}
          >
            <h5>{this.translator.t('tos_ubi.start_sharing_your_driving_data_today')}</h5>
            <span>
              {this.translator.t('tos_ubi.you_can_choose_to_share_driving_data_from_your_vehicle')}
              <a href={carnet_constants.DRIVEVIEW_PRIVACY_URL} target='blank' className='carnetLink'>
                {this.translator.t('tos_ubi.here')}
              </a>
              {this.translator.t('tos_ubi.insurance_related_companies')}
              <a href={carnet_constants.DRIVEVIEW_PRIVACY_URL} target='blank' className='carnetLink'>
                {this.translator.t('tos_ubi.here')}.
              </a>
              {this.translator.t('tos_ubi.learn_more_about_privacy')}
            </span>
            <br />
            <br />
            <span
              className={this.state.dataShareTosStatus === TOS_STATUS.ACCEPT ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
              onClick={e => this.setState({ dataShareTosStatus: TOS_STATUS.ACCEPT })}
            >
              <span className='radioDescText'>{this.translator.t('tos_ubi.agree_to_share_driving_data')}</span>
            </span>
            <br />
            <br />
            <span
              className={this.state.dataShareTosStatus === TOS_STATUS.DECLINE ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
              onClick={e => this.setState({ dataShareTosStatus: TOS_STATUS.DECLINE })}
            >
              <span className='radioDescText'>
                {this.translator.t('tos_ubi.no_do_not_enroll_me_thrid_party_sharing')}
              </span>
            </span>
          </div>
        )}
        <div className='tos-cont-btn-wrapper'>
          <button
            className='cwp mb-5'
            onClick={this.submitTos}
            disabled={this.state.processing || !this.state.tosStatus}
          >
            {this.translator.t('tos_ubi.enroll')}
          </button>
        </div>

        <Modal
          isOpen={this.state.isOpenTosContentModal}
          toggle={this.toggleTosContentModal}
          centered={true}
          className='modal-tos'
        >
          <ModalBody>
            <span className='cwpExit' onClick={this.toggleTosContentModal} />
            <span id='tosBodyWrapper' className='tos-body'>
              {this.fetchTosContent()}
            </span>
            {this.parseAndReplaceAnchors()}
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default TosUBI;
