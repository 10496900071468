import React, { useState, useEffect } from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import Modal from 'react-bootstrap/Modal';
import './ubiDataShare.scss';
import { getVehicleContext } from '../../../providers/historyProvider';
import moment from 'moment';
import { saveTosDetails } from '../providers/EnrollmentTosProvider';
import carnet_constants from '../../../constants/carnet_constants';

const UBIDataShare = props => {
  const translator = getLocaleTranslator();
  const [userId, setUserId] = useState('');
  const [vehicleId, setVehicleId] = useState('');
  const [dataShareTosStatus, setDataShareTosStatus] = useState(null);

  useEffect(() => {
    const vehicleContext = getVehicleContext();
    setVehicleId(vehicleContext?.vehicleId);
    setUserId(vehicleContext?.userId);
  }, []);

  const submitDataShare = async () => {
    const dataShareTos = {
      vehicleId: vehicleId,
      userId: userId,
      toses: [
        {
          tosStatus: dataShareTosStatus,
          tosTimeStamp: moment().valueOf(),
          tosVersion: props.dataShareTosVersion,
          tosType: carnet_constants.UBI_DATA_SHARE
        }
      ]
    };

    try {
      let response = await saveTosDetails(dataShareTos);
      if (response) {
        props.toggleDataShareModal();
      }
    } catch (err) {
      console.log('Error while submit dataShareTOS ::' + err);
    }
  };
  return (
    <div>
      <Modal show={props.displayDataShareModal} onHide={props.toggleDataShareModal} centered={true} backdrop='static'>
        <Modal.Body>
          <div className='data-share'>
            <div className='modal-exit' onClick={props.toggleDataShareModal} />
            <div className='drive-your-way'>{translator.t('dataShare.driveYourWayToAutoDiscounts')}</div>
            <div className='col-md-11 text-left mx-auto'>
              {translator.t('dataShare.weNowAllowYouToShareDrivingData')}
              <a href={carnet_constants.DRIVEVIEW_PRIVACY_URL} target='blank' className='carnetLink'>
                {translator.t('dataShare.here')}
              </a>
              {translator.t('dataShare.insuranceRelated')}
              <a href={carnet_constants.DRIVEVIEW_PRIVACY_URL} target='blank' className='carnetLink'>
                {translator.t('dataShare.here')}
              </a>
              {translator.t('dataShare.privacyStatements')}
            </div>
            <div className='discounted-rates col-md-11 text-left mx-auto'>
              {translator.t('dataShare.discountedInsuranceRatesAvailable')}
            </div>
            <div className='col-md-11 text-left mx-auto my-3'>
              <span
                className={
                  dataShareTosStatus === carnet_constants.TOS_STATUS_ACCEPT
                    ? 'cwpRadio cwpRadioOn radio-btn-text'
                    : 'cwpRadio radio-btn-text'
                }
                onClick={() => setDataShareTosStatus(carnet_constants.TOS_STATUS_ACCEPT)}
              >
                {translator.t('dataShare.iAgreeToShareDrivingData')}
              </span>
              <br />
              <br />
              <span
                className={
                  dataShareTosStatus === carnet_constants.TOS_STATUS_DECLINE
                    ? 'cwpRadio cwpRadioOn radio-btn-text'
                    : 'cwpRadio radio-btn-text'
                }
                onClick={() => setDataShareTosStatus(carnet_constants.TOS_STATUS_DECLINE)}
              >
                {translator.t('dataShare.noDonotEnrollMeInThridParty')}
              </span>
            </div>

            <div className='grid-center'>
              <button
                type='button'
                className='cwp continue-btn'
                onClick={submitDataShare}
                disabled={!dataShareTosStatus}
              >
                {translator.t('dataShare.continue')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UBIDataShare;
