import { createLogic } from 'redux-logic';
import axios from 'axios';
import {
  GET_CUSTOMER_ACCOUNT_DETAILS,
  GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS,
  GET_CUSTOMER_ACCOUNT_DETAILS_FAILURE
} from '../../actions/types';

let dataProvider = axios;

const requestConfig = {
  method: 'GET',
  url: `customer/account`,
  headers: {
    accept: 'application/json'
  }
};

const getCustomerAccountDetailsLogic = createLogic({
  type: GET_CUSTOMER_ACCOUNT_DETAILS,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS,
    failType: GET_CUSTOMER_ACCOUNT_DETAILS_FAILURE
  },

  process ({ action }, dispatch, done) {
    console.log('Processing action in getCustomerAccountDetailsLogic:', action);

    return dataProvider(requestConfig)
      .then(response => {
        console.log('got response for getCustomerAccountDetailsLogic GET request >>> ');
        console.log(JSON.stringify(response, null, 1));
        return response;
      })
      .catch(error => {
        console.error('Error in getCustomerAccountDetailsLogic:', error);
        dispatch({
          type: GET_CUSTOMER_ACCOUNT_DETAILS_FAILURE,
          payload: error.message,
          customerAccountDetailsLoadError: true
        });
      })
      .finally(() => done());
  }
});

export default [getCustomerAccountDetailsLogic];
