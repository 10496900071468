import axios from 'axios';
import invoke from '../../providers/ServiceProxy';

let shouldUseStripe = null;
let stripeApiKey = null;
export const shouldUseStripePaymentPlatform = () => {
  /*
  let urlIdentifiersForStripe = ['.ci.'];
  let currentUrl = window.location.href;
  let shouldUseStripe = urlIdentifiersForStripe.some(id => currentUrl.includes(id));
  // localhost testing
  shouldUseStripe = true;
  return shouldUseStripe;
  */
  return shouldUseStripe;
};

export const getStripeApiKey = () => {
  return stripeApiKey;
};

(async function () {
  let { useStripe } = await invoke({ url: '/paymentplatform' });
  shouldUseStripe = useStripe;
})();

(async function () {
  stripeApiKey = await invoke({ url: '/paymentplatform/key' });
})();
