import React from 'react';
import PropTypes from 'prop-types';
import { findErrorMessage } from '../../../../util/i18n/i18nService';
import { getCountries, getStatesByCountryCode, initAddNewPayment } from '../../providers/EstoreProvider';
import { getLocaleSync } from '../../../../providers/languageProvider';
import { UncontrolledAlert } from 'reactstrap';

// stripe sdk integration
import StripePaymentFormWrapper from './StripePaymentFormWrapper.jsx';

import { isNonEmptyObject } from '../../../../util/utilityMethods';
import { getErrorCodeDesc } from '../../buyPlans/buyPlansUtil';

export default class FormAddNewPaymentStripe extends React.Component {
  static propTypes = {
    translator: PropTypes.object.isRequired,
    availablePaymentoptions: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    alertMessage: null,
    countries: [], //  Dropdown options for country.
    states: {}, //  Dropdown options for states/provience. This is hashed object where key is coutnryCode and value is array of states/provience.
    showStripePaymentForm: true
  };

  componentDidMount () {
    this.initData();
  }

  wrappedShowPaymentForm = (baseURL, authorizationToken, paymentOptionCode) => {
    this.setState({
      paymentFormProps: { baseURL, authorizationToken, paymentOptionCode }
    });
  };

  initData = async () => {
    const locale = getLocaleSync();
    this.setState({ userCountry: locale?.userCountry?.toUpperCase() });

    try {
      const { countries } = await getCountries();
      this.setState({ countries });

      const stateHash = {};
      if (Array.isArray(countries)) {
        for (const { countryCode } of countries) {
          const { states } = await getStatesByCountryCode(countryCode);

          if (Array.isArray(states)) {
            stateHash[countryCode] = states;

            this.setState({ states: stateHash, showStripePaymentForm: true }, () => {
              this.onChangePaymentType({});
            });
          }
        }
      }
    } catch (error) {
      this.setState({ alertMessage: findErrorMessage(error) });
    }
  };

  clearAlerts = () => this.setState({ alertMessage: null });

  getAlerts = () => {
    if (this.state.alertMessage) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts} aria-live='polite' className='w-100'>
          {this.state.alertMessage}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  onChangePaymentType = async e => {
    const optionValue = e?.currentTarget?.value || 'NA';
    const useDifferentAddress = this.state.useNewAddress;

    const callbackURL = window.location.origin + '/user/store/completewallet';
    // const callbackURL = 'https://w-h-s.spr.us00.ci.con-veh.net' + '/user/store/completewallet';

    let initiateWalletData = {
      paymentOptionCode: optionValue,
      useDifferentAddress,
      callbackURL
    };

    const { firstName, lastName, address } = this.props.customer || {};

    initiateWalletData.billingAddress = {
      cardHolderName: `${firstName} ${lastName}`,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2 || undefined,
      zipCode: address.zipCode,
      city: address.city,
      state: address.state,
      country: address.country
    };

    try {
      const response = await initAddNewPayment(initiateWalletData);
      /* // testing code
      response = {
        data: {
          data: {
            baseURL: 'https://uat-fspay.vwcredit.com/web-api/',
            paymentOptionCode: 'AMEX',
            authorizationToken:
              'eyJ0b2tlbkV4SUQiOiIzMzc4NzY0MjI0ODY5MjcwIiwib3JpZ2luIjoiaHR0cHM6Ly91YXQtZnNwYXkudndjcmVkaXQuY29tLGh0dHA6Ly9sb2NhbGhvc3QiLCJ0aW1lc3RhbXAiOiIyMDIwMDgyNTE5MzQyMSIsInRva2VuU2NoZW1lIjoiR1VJRCIsImF1dGhlbnRpY2F0aW9uS2V5IjoiS0wvdUtHd3RpeUN6YTVBN202WUIraEd4TGxUYTk1eHRBUHEvMTNCd3dhMD0iLCJzdG9yZU9wdGlvbk1vZGUiOnRydWUsInVuaXF1ZVJlZmVyZW5jZSI6bnVsbCwiY2FsbGJhY2tVcmwiOiJodHRwOi8vbG9jYWxob3N0OjgwMDAvIy9idXktcGxhbnMvNGUxNGJhYWYtMDRkNC0zYWE4LWJkNGYtNzM1Y2ZmMTU4ZmU3dXNlci9zdG9yZS9jb21wbGV0ZXdhbGxldCJ9',
            paymentProviderResponse:
              'eyJ0b2tlbkV4SUQiOiIzMzc4NzY0MjI0ODY5MjcwIiwib3JpZ2luIjoiaHR0cHM6Ly91YXQtZnNwYXkudndjcmVkaXQuY29tLGh0dHA6Ly9sb2NhbGhvc3QiLCJ0aW1lc3RhbXAiOiIyMDIwMDgyNTE5MzQyMSIsInRva2VuU2NoZW1lIjoiR1VJRCIsImF1dGhlbnRpY2F0aW9uS2V5IjoiS0wvdUtHd3RpeUN6YTVBN202WUIraEd4TGxUYTk1eHRBUHEvMTNCd3dhMD0iLCJzdG9yZU9wdGlvbk1vZGUiOnRydWUsInVuaXF1ZVJlZmVyZW5jZSI6bnVsbCwiY2FsbGJhY2tVcmwiOiJodHRwOi8vbG9jYWxob3N0OjgwMDAvIy9idXktcGxhbnMvNGUxNGJhYWYtMDRkNC0zYWE4LWJkNGYtNzM1Y2ZmMTU4ZmU3dXNlci9zdG9yZS9jb21wbGV0ZXdhbGxldCJ9'
          }
        }
      };
      */
      // console.log('what is the wallet initiate response >>> ');
      // console.log(JSON.stringify(response));

      this.setState({ initAddPaymentResponseData: response?.data });

      const { baseURL, authorizationToken, paymentOptionCode } = response?.data?.data || response?.data || {};

      if (baseURL) {
        this.wrappedShowPaymentForm(baseURL, authorizationToken, paymentOptionCode);

        return;
      } else if (isNonEmptyObject(response?.data?.error)) {
        this.setState({ alertMessage: getErrorCodeDesc(response?.data?.error?.errorCode) });
      }
    } catch (err) {
      this.setState({ alertMessage: findErrorMessage(err) });
    }
  };

  render () {
    const { translator, availablePaymentoptions, customer } = this.props;
    return (
      <section className='container-fluid my-5 py-4 border'>
        <h2 className='font-weight-light mx-auto my-3'>{translator.t('buyplans.label.add-a-new-payment')}</h2>
        {this.getAlerts()}
        <section className='row w-100 mt-5 justify-content-between'>
          {this.state.showStripePaymentForm && (
            <article className='col-12 col-md-6 d-flex flex-column py-3'>
              <span>{translator.t('buyplans.label.prepaid-not-accepted-part1')}</span>
              <span>{translator.t('buyplans.label.prepaid-not-accepted-part2')}</span>
              <div id='paymentFormBackground'>
                {this.state.paymentFormProps && (
                  <StripePaymentFormWrapper
                    paymentFormProps={this.state.paymentFormProps}
                    translator={translator}
                    customer={customer}
                  />
                )}
                <div id='cwPaymentFormDiv'></div>
              </div>
            </article>
          )}
        </section>
      </section>
    );
  }
}
