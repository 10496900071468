import React, { useState, useEffect } from 'react';
import { getLocaleTranslator, findErrorMessage } from '../../../util/i18n/i18nService';
import './unEnrollUBIDataShare.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, UncontrolledAlert } from 'reactstrap';
import { getVehicleContext } from '../../../providers/historyProvider';
import { getEnrollmentTosDetails, saveTosDetails } from '../providers/EnrollmentTosProvider';
import moment from 'moment';
import carnet_constants from '../../../constants/carnet_constants';
import Loader from '../common/loader';
import getCwpMeta from '../../../providers/cwpMetaProvider';

const UnEnrollUBIDataShare = () => {
  const translator = getLocaleTranslator();

  const [driveViewTosStatus, setDriveViewTosStatus] = useState(null);
  const [dataShareTosStatus, setDataShareTosStatus] = useState(null);
  const [userId, setUserId] = useState(null);
  const [vehicleId, setVehicleId] = useState(null);
  const [ubiMetadata, setUbiMetadata] = useState(null);
  const [dataShareMetadata, setDataShareMetadata] = useState(null);
  const [unEnrollDriviewModal, setUnEnrollDriviewModal] = useState(false);
  const [unEnrollDataShareModal, setUnEnrollDataShareModal] = useState(false);
  const [errorMsgAlert, setErrorMsgAlert] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [secureContentUrl, setSecureContentUrl] = useState(null);
  const [dataShareTosExists, setDataShareTosExists] = useState(null);

  useEffect(() => {
    fetchTosDetails();
    const vehicleContext = getVehicleContext();
    setVehicleId(vehicleContext?.vehicleId);
    setUserId(vehicleContext?.userId);
  }, []);

  const fetchTosDetails = async () => {
    try {
      setProcessing(true);
      let response = await getEnrollmentTosDetails();
      let cwpMetaData = await getCwpMeta();

      const { toses, eligibleToses, tosMetaData } = response?.data || {};

      setDataShareTosExists(eligibleToses.includes(carnet_constants.UBI_DATA_SHARE));
      setSecureContentUrl(cwpMetaData?.static?.content?.urls?.securecontent);
      setUbiMetadata(getMetaData(tosMetaData, carnet_constants.UBI));
      setDataShareMetadata(getMetaData(tosMetaData, carnet_constants.UBI_DATA_SHARE));

      const ubiTos = toses.find(tos => tos.tosType === carnet_constants.UBI);
      const ubiDataShareTos = toses.find(tos => tos.tosType === carnet_constants.UBI_DATA_SHARE);
      setDataShareTosStatus(ubiDataShareTos?.tosStatus);
      setDriveViewTosStatus(ubiTos?.tosStatus);
    } catch (error) {
      setErrorMsgAlert(findErrorMessage(error, translator));
    } finally {
      setProcessing(false);
    }
  };

  const getMetaData = (metaData, manifestId) => {
    return metaData?.manifest?.find(manifest => manifest.id === manifestId);
  };

  const submitDataShare = async () => {
    const toses = {
      vehicleId: vehicleId,
      userId: userId,
      toses: [
        {
          tosStatus: driveViewTosStatus,
          tosTimeStamp: moment().valueOf(),
          tosVersion: ubiMetadata?.tosVersion,
          tosType: carnet_constants.UBI
        }
      ]
    };

    if (dataShareTosExists) {
      toses.toses.push({
        tosStatus: dataShareTosStatus,
        tosTimeStamp: moment().valueOf(),
        tosVersion: dataShareMetadata?.tosVersion,
        tosType: carnet_constants.UBI_DATA_SHARE
      });
    }
    try {
      setProcessing(true);
      let response = await saveTosDetails(toses);
      if (response) {
        navBackToPrevPage();
      }
    } catch (error) {
      setErrorMsgAlert(findErrorMessage(error, translator));
    } finally {
      setProcessing(false);
    }
  };

  const navBackToPrevPage = () => {
    window.history.back();
  };

  const toggleUnEnrollDriviewModal = e => {
    if (driveViewTosStatus === carnet_constants.TOS_STATUS_ACCEPT && !e.currentTarget.checked) {
      setUnEnrollDriviewModal(!unEnrollDriviewModal);
    } else {
      setDriveViewTosStatus(carnet_constants.TOS_STATUS_ACCEPT);
    }
  };

  const toggleUnEnrollDataShareModal = e => {
    if (dataShareTosStatus === carnet_constants.TOS_STATUS_ACCEPT && !e.currentTarget.checked) {
      setUnEnrollDataShareModal(!unEnrollDataShareModal);
    } else if (e.currentTarget.checked) {
      setDataShareTosStatus(carnet_constants.TOS_STATUS_ACCEPT);
    }
  };

  const toggleDriveViewTosStatus = () => {
    setDriveViewTosStatus(carnet_constants.TOS_STATUS_DECLINE);
    setDataShareTosStatus(carnet_constants.TOS_STATUS_DECLINE);
    setUnEnrollDriviewModal(!unEnrollDriviewModal);
  };

  const toggleDataShareTosStatus = () => {
    setDataShareTosStatus(carnet_constants.TOS_STATUS_DECLINE);
    setUnEnrollDataShareModal(!unEnrollDataShareModal);
  };

  const clearAlerts = () => {
    setErrorMsgAlert(null);
  };

  const getAlerts = () => {
    if (errorMsgAlert) {
      return (
        <UncontrolledAlert color='danger' toggle={clearAlerts}>
          {errorMsgAlert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  return (
    <div className='unEnrollUbiDataShare'>
      {processing && <Loader />}
      <div class='row mt-3'>
        <div className='col-sm-2 text-left'>
          <div className='cwpBack editor-navback-arrow-text' onClick={navBackToPrevPage}>
            {translator.t('acctmgmt_back_label')}
          </div>
        </div>
        <div className='col-sm-9 text-center'>
          <div className='editHeader'>{translator.t('acctmgmt_pi_edit_link_label')}</div>
        </div>
      </div>
      {getAlerts()}
      <div className='justify-self-center container-lg mb-5 py-4 pageHeight'>
        <div className='text-left'>
          <div className='driveViewHeader'>{translator.t('acctmgmt_driveview_settings_label')}</div>
        </div>
        <hr className='border-1 bg-gray' />
        <div className='row'>
          <div className='col'>
            <article className='d-inline-flex mt-4'>
              <input
                id='driveViewTermsOfService'
                name='driveViewTermsOfService'
                type='checkbox'
                required={true}
                checked={driveViewTosStatus === carnet_constants.TOS_STATUS_ACCEPT}
                className={`cwpCheckbox ${
                  driveViewTosStatus === carnet_constants.TOS_STATUS_ACCEPT ? 'cwpCheckboxOn' : 'cwpCheckboxOff'
                }`}
                onChange={e => toggleUnEnrollDriviewModal(e)}
                tabIndex='0'
                value={carnet_constants.TOS_STATUS_ACCEPT}
              />
              <label htmlFor='driveViewTermsOfService' className='tosTypesText'>
                {translator.t('acctmgmt_driveview_label')}
                <sup>1</sup>
              </label>
            </article>
            <div className='driveViewConsent'>
              <span className='driveViewConsentText'>{translator.t('acctmgmt_i_consent_to_receiving')}</span>
              <a href={secureContentUrl + ubiMetadata?.tosUrl} target='blank' className='carnetLink'>
                {translator.t('acctmgmt_driveview_terms')}
              </a>
              <span className='consentText'>{translator.t('acctmgmt_here')}</span>
            </div>
          </div>
          {dataShareTosExists && (
            <div
              className={`col mr-5 ${
                driveViewTosStatus === carnet_constants.TOS_STATUS_DECLINE ? 'disableDataShare' : ''
              }`}
            >
              <article className='d-inline-flex mt-4'>
                <input
                  id='dataShareTermsOfService'
                  name='dataShareTermsOfService'
                  type='checkbox'
                  required={true}
                  checked={dataShareTosStatus === carnet_constants.TOS_STATUS_ACCEPT}
                  className={`cwpCheckbox ${
                    dataShareTosStatus === carnet_constants.TOS_STATUS_ACCEPT ? 'cwpCheckboxOn' : 'cwpCheckboxOff'
                  }`}
                  onChange={e => toggleUnEnrollDataShareModal(e)}
                  tabIndex='0'
                  value={carnet_constants.TOS_STATUS_ACCEPT}
                />
                <label htmlFor='dataShareTermsOfService' className='tosTypesText'>
                  {translator.t('accmgmt_thrid_party_data_share')}
                  <sup>2</sup>
                </label>
              </article>
              <div className='consentText'>{translator.t('accmgmt_i_consent_to_sharing')}</div>
            </div>
          )}
        </div>
        <div className='driveViewNote'>{translator.t('acctmgmt_driview_notes')}</div>
        {dataShareTosExists && <div className='driveViewNote'>{translator.t('acctmgmt_datashare_notes')}</div>}

        <div className='row mb-5 mt-5'>
          <div className='offset-md-7'>
            <button type='button' className='cwp saveChangesBtn' onClick={submitDataShare}>
              {translator.t('acctmgmt_save_changes')}
            </button>
          </div>
        </div>
      </div>

      <Modal isOpen={unEnrollDataShareModal} className='carnet-sg-modal unEnrollUbiDataShare'>
        <ModalHeader>
          <div className='areYouSureHeader'>{translator.t('acctmgmt_r_u_sure')}</div>
        </ModalHeader>
        <ModalBody>
          <div className='byEnrollingBody'>{translator.t('acctmgmt_by_unenrolling_in_our_thirdparty_datashare')}</div>
        </ModalBody>
        <ModalFooter className='design-deviation-footer'>
          <Button
            className='modal-primary-btn modalBtn mb-4'
            color='modal-background-color'
            onClick={toggleDataShareTosStatus}
          >
            {translator.t('acctmgmt_unenroll_btn')}
          </Button>
          <Button
            className='modal-secondary-btn modalBtn'
            color='modal-text-color'
            onClick={toggleUnEnrollDataShareModal}
          >
            {translator.t('acctmgmt_cancel')}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={unEnrollDriviewModal} className='carnet-sg-modal unEnrollUbiDataShare'>
        <ModalHeader>
          <div className='areYouSureHeader'>{translator.t('acctmgmt_r_u_sure')}</div>
        </ModalHeader>
        <ModalBody>
          <div className='byEnrollingBody'>{translator.t('acctmgmt_by_unenrolling_in_our_driveview')}</div>
        </ModalBody>
        <ModalFooter className='design-deviation-footer'>
          <Button
            className='modal-primary-btn modalBtn mb-4'
            color='modal-background-color'
            onClick={toggleDriveViewTosStatus}
          >
            {translator.t('acctmgmt_unenroll_btn')}
          </Button>
          <Button
            className='modal-secondary-btn modalBtn'
            color='modal-text-color'
            onClick={toggleUnEnrollDriviewModal}
          >
            {translator.t('acctmgmt_cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UnEnrollUBIDataShare;
