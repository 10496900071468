import { GET_CAR_NICK_NAME, GET_CAR_NICK_NAME_SUCCESS, GET_CAR_NICK_NAME_FAILURE } from '../../actions/types';

const initialState = {
  carNickNameData: null
};

const carNickNameReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const responseData = payload?.data || null;
  let newState = { ...state };
  switch (type) {
    case GET_CAR_NICK_NAME:
      return newState;

    case GET_CAR_NICK_NAME_SUCCESS:
      return {
        ...state,
        carNickNameData: responseData
      };

    case GET_CAR_NICK_NAME_FAILURE:
      return {
        ...state,
        carNickNameData: null
      };

    default:
      return state;
  }
};

export default carNickNameReducer;
