import React from 'react';
import PropTypes from 'prop-types';
import { Parser as HtmlToReactParser } from 'html-to-react';
import { getLocaleTranslator, findErrorMessage } from '../../../../util/i18n/i18nService';
import { UncontrolledAlert, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import defaultVehicleImage from '../../../../assets/images/defaultVehicleImage.png';

import {
  findApplicableTos,
  TOS_TYPE,
  TSP_PROVIDER,
  submitTosCarnet,
  submitTosCarnetVZT,
  TOS_STATUS,
  submitTosCarnetUBI,
  submitTosDataTrial
} from '../../providers/SelfEnrollmentProvider';
import TosComponentDataTrial from './TosComponentDataTrial';

export default class TosCarnetWithAddons extends React.Component {
  static propTypes = {
    addonTosTypes: PropTypes.array.isRequired,
    vin: PropTypes.string.isRequired,
    imgURL: PropTypes.string.isRequired,
    modelYear: PropTypes.string.isRequired,
    modelName: PropTypes.string.isRequired,
    defaultMno: PropTypes.string.isRequired,
    tspProvider: PropTypes.string.isRequired,
    afterSubmit: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);

    this.translator = getLocaleTranslator();
  }

  state = {
    alert: null,
    processing: false,
    tosStatus: null,
    isOpenTosContentModal: false,
    isOpenTosContentModalUBI: false,
    tosVersion: null,
    tosVersionUBI: null,
    tosContent: null,
    tosContentUBI: null,
    tosVersionDataTrial: null,
    tosStatusDataTrial: null,
    tspAccountNum: null,
    tspPIN: null,
    pinVisible: false,
    showDeclineConfirmation: false,
    ubiFullTosUrl: null
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    let tosVersion = null,
      tosContent = null,
      tosVersionUBI = null,
      tosContentUBI = null,
      tosVersionDataTrial = null,
      ubiFullTosUrl = null;

    this.setState({ processing: true });

    const p1 = findApplicableTos(TOS_TYPE.CARNET).then(data => {
      tosVersion = data.tosVersion;
      tosContent = data.tosContent;
    });

    const p2 = findApplicableTos(TOS_TYPE.UBI).then(data => {
      tosVersionUBI = data.tosVersion;
      tosContentUBI = data.tosContent;
      ubiFullTosUrl = data?.fullTosUrl;
    });

    const p3 = findApplicableTos(TOS_TYPE.DATA_TRIAL).then(data => {
      tosVersionDataTrial = data.tosVersion;
    });

    Promise.all([p1, p2, p3])
      .then(() => {
        this.setState({
          tosVersion,
          tosContent,
          tosVersionUBI,
          tosContentUBI,
          tosVersionDataTrial,
          processing: false,
          ubiFullTosUrl
        });
      })
      .catch(error => {
        this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
      });
  };

  // helper function to run on rendered html
  // need to hijack <anchor></anchor> tags because of react-router
  parseAndReplaceAnchors = () => {
    setTimeout(() => {
      let el = document.getElementById('tosBodyWrapper');
      let anchors = (el && el.querySelectorAll('a')) || [];
      anchors.forEach(element => {
        let href = element.getAttribute('href');
        let isHash = href && String(href).includes('#');
        let partsArr = isHash && String(href).split('#');
        let divId = null;
        if (Array.isArray(partsArr) && partsArr[0] === '' && partsArr[1]) {
          divId = partsArr[1];
        }
        let div = divId ? document.getElementById(divId) : null;
        if (divId && div) {
          element.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
            div.scrollIntoView();
          });
          if (isHash) {
            element.setAttribute('href', '#');
          }
        }
      });
    }, 250);
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  parseContent = content => {
    const parser = new HtmlToReactParser();

    return parser.parse(content);
  };

  setTosStatus = tosStatus => {
    this.setState({ tosStatus });
  };

  checkAcceptanceThanSubmit = () => {
    if (this.state.tosStatus === TOS_STATUS.ACCEPT) {
      return this.submitTos();
    }
    this.setState({ showDeclineConfirmation: true });
  };

  confirmDeclinationAndContinue = () => {
    this.setState({ showDeclineConfirmation: false }, this.submitTos);
  };

  cancelDeclinationAndContinue = () => {
    this.setState({ showDeclineConfirmation: false });
  };

  submitTos = async () => {
    this.setState({ processing: true, alert: null });

    let submitResponseCarnet = null,
      submitResponseUBI = null,
      submitResponseDataTrial = null;

    try {
      if (this.props.tspProvider === TSP_PROVIDER.VZT) {
        submitResponseCarnet = await submitTosCarnetVZT(
          this.state.tosVersion,
          this.state.tosStatus,
          this.state.tspAccountNum,
          this.state.tspPIN
        );
      } else {
        submitResponseCarnet = await submitTosCarnet(this.state.tosVersion, this.state.tosStatus);
      }

      submitResponseUBI = await submitTosCarnetUBI(this.state.tosVersionUBI, this.state.tosStatus);

      if (this.state.tosStatusDataTrial) {
        submitResponseDataTrial = await submitTosDataTrial(
          this.state.tosStatusDataTrial,
          this.state.tosVersionDataTrial
        );
      }

      this.setState({ processing: false });
      this.props.afterSubmit(this.state.tosStatus, submitResponseCarnet, submitResponseUBI, submitResponseDataTrial);
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  toggleTosContentModal = () => {
    this.setState({ isOpenTosContentModal: !this.state.isOpenTosContentModal });
  };

  toggleTosContentModalUBI = () => {
    this.setState({ isOpenTosContentModalUBI: !this.state.isOpenTosContentModalUBI });
  };

  isInvalidVztEnrollmentSubmission = () => {
    return this.props.enrollmentProcedure === 'VZT' && (!this.state.tspAccountNum || !this.state.tspPIN);
  };

  onBackClick = () => window.history.back();

  render () {
    const imgUrl = this.props.imgURL || defaultVehicleImage;
    const vehicleName = this.props.modelYear + ' ' + this.props.modelName;

    return (
      <>
        <div className='enrollmentFullWidthLeft'>
          <span className='cwpBack' role='button' onClick={this.onBackClick}>
            {this.translator.t('button.back')}
          </span>
        </div>
        <h1 className='font-weight-normal'>
          {this.translator.t('tos_carnet.your')} <strong>{this.translator.t('tos_carnet.vehicle')}</strong>
        </h1>
        {this.getAlerts()}

        <div className='d-flex flex-column align-items-center'>
          <img src={imgUrl} alt={' '} width='300' />
          <article className='text-left'>
            <h1 className='font-weight-normal'>{vehicleName}</h1>
            <h6>
              {this.translator.t('tos_carnet.vin')}: {this.props.vin}
            </h6>
          </article>
          {this.props.enrollmentProcedure === 'VZT' ? (
            <span className='d-block'>
              <div className='form-group'>
                <label htmlFor='tspAccountNum'>{this.translator.t('tos_carnet.account_number')}</label>
                <br />
                <input
                  id='tspAccountNum'
                  type='text'
                  className='cwp'
                  maxLength='9'
                  autoComplete={false}
                  pattern='[0-9]{9}'
                  required='true'
                  onChange={e => this.setState({ tspAccountNum: e.currentTarget.value })}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='tspPin'>{this.translator.t('tos_carnet.pin')}</label>
                <br />
                <input
                  id='tspPin'
                  type={this.state.pinVisible ? 'text' : 'password'}
                  className='cwp'
                  maxLength='4'
                  autoComplete={false}
                  required={true}
                  pattern='[0-9]{4}'
                  title={this.translator.t('pin_prompt.4_digit_number_only')}
                  onChange={e => this.setState({ tspPIN: e.currentTarget.value })}
                />
                <span
                  className={this.state.pinVisible ? 'cwpInputEye cwpInputEyeOpen' : 'cwpInputEye'}
                  onClick={e => this.setState({ pinVisible: !this.state.pinVisible })}
                />
              </div>
            </span>
          ) : (
            ''
          )}
        </div>
        <div className='col-md-8 text-left mx-auto my-5 d-flex flex-column align-items-start lead'>
          <h1>{this.translator.t('tos_carnet.important_information')}</h1>
          <ul className='small'>
            <li>{this.translator.t('tos_carnet.important_information_1')}</li>
            <li>{this.translator.t('tos_carnet.important_information_2')}</li>
            <li>{this.translator.t('tos_carnet.important_information_3')}</li>
            <li>{this.translator.t('tos_carnet.important_information_4')}</li>
          </ul>
          <hr className='w-100' />
          <article className='align-self-start'>
            {this.translator.t('tos_carnet.i_have_read_and_accept_the')}
            <span className='carnetLink' onClick={this.toggleTosContentModal}>
              {this.translator.t('tos_carnet.carnet_terms_of_services')}
            </span>
            ,
            <a href={this.state.ubiFullTosUrl} target='blank' className='carnetLink'>
              {this.translator.t('tos_carnet.drive_view_terms')}
            </a>
            {this.translator.t('tos_carnet.and')}
            <a href='https://www.vw.com/privacy/' target='blank' className='carnetLink'>
              {this.translator.t('tos_carnet.privacy_statement')}
            </a>
            .
          </article>
          <span
            className={`my-3 d-flex align-items-center cwpRadio ${
              TOS_STATUS.ACCEPT === this.state.tosStatus ? 'cwpRadioOn' : ''
            }`}
            onClick={() => this.setTosStatus(TOS_STATUS.ACCEPT)}
          >
            {this.translator.t('tos_carnet.accept')}
          </span>
          <span
            className={`mb-3 d-flex align-items-center cwpRadio ${
              TOS_STATUS.DECLINE === this.state.tosStatus ? 'cwpRadioOn' : ''
            }`}
            onClick={() => this.setTosStatus(TOS_STATUS.DECLINE)}
          >
            {this.translator.t('tos_carnet.decline')}
          </span>
          <hr className='w-100' />
          <TosComponentDataTrial
            defaultMno={this.props.defaultMno}
            onChange={tosStatusDataTrial => this.setState({ tosStatusDataTrial })}
          />
        </div>

        <div className='tos-cont-btn-wrapper'>
          <button
            className='cwp mb-5'
            onClick={this.checkAcceptanceThanSubmit}
            disabled={this.state.processing || !this.state.tosStatus || this.isInvalidVztEnrollmentSubmission()}
          >
            {this.translator.t('tos_carnet.submit')}
          </button>
        </div>

        <Modal isOpen={this.state.showDeclineConfirmation} className='carnet-sg-modal tosDeclinationConfirmation'>
          <ModalHeader>{''}</ModalHeader>
          <ModalBody>{this.translator.t('tos_carnet.decline_confirmation')}</ModalBody>
          <ModalFooter>
            <div>
              <Button
                className='modal-primary-btn'
                color='modal_background_color'
                onClick={this.confirmDeclinationAndContinue}
              >
                {this.translator.t('button.ok')}
              </Button>
            </div>
            <div>
              <Button
                className='modal-secondary-btn'
                color='modal_background_color'
                onClick={this.cancelDeclinationAndContinue}
              >
                {this.translator.t('button.cancel')}
              </Button>
            </div>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenTosContentModal}
          toggle={this.toggleTosContentModal}
          centered={true}
          className='modal-tos'
        >
          <ModalBody>
            <span className='cwpExit' onClick={this.toggleTosContentModal} />
            <span id='tosBodyWrapper' className='tos-body'>
              {this.parseContent(this.state.tosContent)}
            </span>
            {this.parseAndReplaceAnchors()}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.isOpenTosContentModalUBI}
          toggle={this.toggleTosContentModalUBI}
          centered={true}
          className='modal-tos'
        >
          <ModalBody>
            <span className='cwpExit' onClick={this.toggleTosContentModalUBI} />
            <span id='tosBodyWrapper' className='tos-body'>
              {this.parseContent(this.state.tosContentUBI)}
            </span>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
