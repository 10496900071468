/*
VW US:pk_test_51Oz4wmB3Cbyyeob4lYrRUmuk4qhVqy7kY1Rsl0GMk0iBRjjXY9M1KYaMb9gGnBbhIGMEjQWQtKPqVu6OJt2AvKdc00afZXHrLT
VW CA:pk_test_51P6G9KGgwfEkrZmqHpNxYtb3IuBzysZv4V6XwEHGXg4Yyx8tHjIkpC32W7KjO2Zpf4YITYbNvGq4xaqGjz3OO6a600JOJnQGi0
*/

import React, { useState, useEffect } from 'react';
import { useStripe, useElements, Elements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import { getLocale } from '../../../../providers/languageProvider';
import resource from '../../../../config/resource.json';
import { getStripeApiKey } from './paymentPlatformProviders';

// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
let stripePromise = null;
async function loadStripeObject() {
  let stripeSdkKey = getStripeApiKey();
  if (stripePromise === null) {
    stripePromise = await loadStripe(stripeSdkKey);
  }
  return stripePromise;
}

function StripePaymentFormWrapper({ paymentFormProps, translator, customer }) {
  // As of 09/2024 no purchaseable articles in CA
  const DEFAULT_COUNTRY_CODE = 'US';
  // allowedCountries will always only be one country (US or CA), array format is required for Stripe SDK
  const [allowedCountries, setAllowedCountries] = useState([DEFAULT_COUNTRY_CODE]);
  const [pendingSubmission, setPendingSubmission] = useState(false);

  useEffect(() => {
    async function getCountry() {
      let localeInfo = await getLocale();
      let country = localeInfo.userCountry.toUpperCase();
      setAllowedCountries([country]);
    }
    getCountry();
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const [addressOptions, setAddressOptions] = useState({
    mode: 'billing',
    autoComplete: { mode: 'google_maps_api', apiKey: resource.google_map.key }, // apiKey usage same as other places in app
    blockPoBox: true,
    allowedCountries: allowedCountries,
    defaultValues: {
      name: `${customer?.firstName} ${customer?.lastName}`,
      address: {
        line1: customer?.address?.addressLine1,
        line2: customer?.address?.addressLine2,
        city: customer?.address?.city,
        state: customer?.address?.state,
        postal_code: customer?.address?.zipCode,
        country: customer?.address?.country || allowedCountries[0] || DEFAULT_COUNTRY_CODE // 'US' default (only 'US' or 'CA')
      }
    }
  });

  const submitStripeForm = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setPendingSubmission(true);
    let { authorizationToken } = paymentFormProps;
    const callbackURL = window.location.origin + '/buy-plans/checkout/payment';

    return elements
      .submit()
      .then(async (result) => {
        let { error } = await stripe.confirmSetup({
          clientSecret: authorizationToken,
          elements,
          confirmParams: {
            return_url: callbackURL
          }
        });
        if (error) {
          console.log(error);
        }
      })
      .catch((error) => {
        console.log('Stripe elements submit error ', error);
      })
      .finally(() => {
        setPendingSubmission(false);
      });
  };

  return (
    <form>
      <PaymentElement disabled={pendingSubmission} />
      <AddressElement disabled={pendingSubmission} options={addressOptions} />
      <button disabled={pendingSubmission} type='button' className='cwp cwpPrimary mx-auto' onClick={submitStripeForm}>
        {translator.t('SUBMIT')}
      </button>
    </form>
  );
}

export default function ({ paymentFormProps, translator, customer, newAddress }) {
  const [stripePromiseReady, setStripePromiseReady] = useState(false);

  useEffect(() => {
    async function onload() {
      let stripePromiseReadyInit = await loadStripeObject();
      setStripePromiseReady(stripePromiseReadyInit);
    }
    onload();
  }, []);

  const elementOptions = {
    mode: 'setup',
    currency: 'usd',
    wallets: {
      applePay: 'never',
      googlePay: 'never'
    },
    appearance: {
      theme: 'flat'
    }
  };
  return (
    <div className='stripeFormWrapper' id='stripeForm'>
      {stripePromiseReady && (
        <Elements stripe={stripePromise} options={elementOptions}>
          <StripePaymentFormWrapper
            paymentFormProps={paymentFormProps}
            translator={translator}
            customer={customer}
            newAddress={newAddress}
          />
        </Elements>
      )}
    </div>
  );
}
