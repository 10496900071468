/* eslint-disable complexity */
import React, { Component } from 'react';
import './tripStats.scss';
import { Row } from 'reactstrap';
import info from '../../../assets/tripStats/info.png';
import down from '../../../assets/tripStats/chevron-down.png';
import TripStatsGraph from './TripStatsGraph';
import axios from 'axios';
import TripStatsHistory from './TripStatsHistory';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import ArrowLeft from '../../../assets/tripStats/arrow-left.png';
import ArrowRight from '../../../assets/tripStats/arrow-right.png';
import Loader from '../common/loader';
import DriveViewInfoModal from './DriveViewInfoModal';
import DriveViewFeatures from './DriveViewFeatures';
import {
  getWeekTrackingDate,
  getMonthTrackingDate,
  getTodaysDate,
  getWeekDisplayDate,
  getMonthDisplayDate,
  getEpochStartWeek,
  getEpochEndWeek,
  getEpochMonthStart,
  getEpochMonthEnd,
  getWeeklyStartDate
} from '../common/commonDateUtil';
import EnrollTripWise from './EnrollTripWise';
import { createBrowserHistory } from 'history';
import TripStatsTableWeekly from './TripStatsTableWeekly';
import TripStatsTableMonthly from './TripStatsTableMonthly';
import { isUserPrimary } from '../../../providers/userRolesProvider';
import { getEnrollmentTosDetails } from '../providers/EnrollmentTosProvider';
import UpdateUBITerms from '../driveView/UpdateUBITerms';
import carnet_constants from '../../../constants/carnet_constants';

const TripsViewTypes = Object.freeze({
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
});

const TripsDataPresentationTypes = Object.freeze({
  GRAPH: 'graph',
  TABLE: 'table'
});

class TripStatsHeader extends Component {
  constructor () {
    super();
    this.state = {
      expandDriveView: true,
      collapseDriveView: false,
      remoteTripDetails: {},
      graphData: {},
      monthlyGraphData: {},
      aboutDriveViewModal: false,
      trackDate: '',
      previousSelected: false,
      nextSelected: false,
      dataLoading: false,
      epochStartDate: '',
      epochEndDate: '',
      RenderTripWise: false,
      path: '',
      viewType: TripsViewTypes.WEEKLY,
      dataPresentationType: TripsDataPresentationTypes.GRAPH,
      UBITosVersionUpdate: false,
      ubiManifest: '',
      displayUBITosModal: false
    };
    this.translator = getLocaleTranslator();
    this.toggleAboutDriveViewModal = this.toggleAboutDriveViewModal.bind(this);
    this.viewDriveViewFeatures = this.viewDriveViewFeatures.bind(this);
    this.callDriveView = this.callDriveView.bind(this);
    this.toggleUBITosModal = this.toggleUBITosModal.bind(this);
  }

  componentDidMount () {
    this.getRemoteTripDetails();
    const history = createBrowserHistory();
    this.setState({ path: history.location.hash });
    this.initTosDetails();
  }

  getRemoteTripDetails = () => {
    let startDate;
    let endDate;
    let trackingDate = null;

    if (this.state.viewType === TripsViewTypes.WEEKLY) {
      startDate = getEpochStartWeek(getWeeklyStartDate());
      endDate = getEpochEndWeek(getWeeklyStartDate());
      trackingDate = getWeeklyStartDate();
    } else if (this.state.viewType === TripsViewTypes.MONTHLY) {
      startDate = getEpochMonthStart(getTodaysDate());
      endDate = getEpochMonthEnd(getTodaysDate());
    }
    this.setState({ trackDate: trackingDate === null ? getTodaysDate() : trackingDate }, () => {
      this.getTripsDetails(startDate, endDate);
    });
  };

  getTripsDetails = async (startDate, endDate) => {
    try {
      let response = await axios.get(
        `/rts/weekormonth?type=${this.state.viewType}&startDate=${startDate}&endDate=${endDate}`
      );
      if (response && response.data) {
        this.setState({
          remoteTripDetails: response.data.data
        });
      }
    } catch (error) {
      this.setState({
        remoteTripDetails: {}
      });
    }

    try {
      let response = await axios.get(
        `/rts/graphdata?type=${this.state.viewType}&startDate=${startDate}&endDate=${endDate}`
      );
      if (response && response.data) {
        this.setState({ graphData: response.data.data, dataLoading: true });
      }
    } catch (error) {
      this.setState({
        graphData: {},
        dataLoading: true
      });
    }
  };

  initTosDetails = async () => {
    if (isUserPrimary()) {
      try {
        const response = await getEnrollmentTosDetails();

        const { eligibleToses, toses } = response?.data || {};
        const manifestData = response?.data?.tosMetaData?.manifest || {};

        const ubiManifest = manifestData.find(manifest => manifest.id === carnet_constants.UBI);
        const acceptedUbiTosVersion = toses.find(tos => tos.tosType === carnet_constants.UBI)?.tosVersion;

        if (eligibleToses.includes(carnet_constants.UBI) && acceptedUbiTosVersion !== ubiManifest?.tosVersion) {
          this.setState({ displayUBITosModal: !this.state.UBITosVersionUpdate, ubiManifest });
        }
      } catch (error) {
        console.error('Error while getting enrollment tos details:::' + error);
      }
    }
  };

  toggleUBITosModal = () => {
    this.setState({ displayUBITosModal: !this.state.displayUBITosModal });
  };

  viewDriveViewFeatures = () => {
    this.setState({ expandDriveView: !this.state.expandDriveView, collapseDriveView: !this.state.collapseDriveView });
  };

  toggleAboutDriveViewModal = () => {
    this.setState({ aboutDriveViewModal: !this.state.aboutDriveViewModal });
  };

  handlePrevNext = (previousSelected, nextSelected) => {
    let weekTrackingDate;
    let monthTrackingDate;

    if (this.state.viewType === TripsViewTypes.WEEKLY) {
      weekTrackingDate = getWeekTrackingDate(true, previousSelected, nextSelected, this.state.trackDate);
    } else {
      monthTrackingDate = getMonthTrackingDate(true, previousSelected, nextSelected, this.state.trackDate);
    }

    this.setState(
      {
        previousSelected: previousSelected,
        nextSelected: nextSelected,
        trackDate: this.state.viewType === TripsViewTypes.WEEKLY ? weekTrackingDate : monthTrackingDate
      },
      () => {
        this.getNextPrevTripsDetails(weekTrackingDate, monthTrackingDate);
      }
    );
  };

  getNextPrevTripsDetails = (weekTrackingDate, monthTrackingDate) => {
    let startDate;
    let endDate;

    if (this.state.viewType === TripsViewTypes.WEEKLY && weekTrackingDate) {
      startDate = getEpochStartWeek(weekTrackingDate);
      endDate = getEpochEndWeek(weekTrackingDate);
    } else if (this.state.viewType === TripsViewTypes.MONTHLY && monthTrackingDate) {
      startDate = getEpochMonthStart(monthTrackingDate);
      endDate = getEpochMonthEnd(monthTrackingDate);
    }

    this.getTripsDetails(startDate, endDate);
  };

  callDriveView = () => {
    this.setState({ RenderTripWise: true });
  };

  // natural radio onChange event
  onChangeViewTypeToggleValue = e => {
    let viewType = e.target.value;
    this.setState({ viewType }, this.getRemoteTripDetails);
  };

  // click that would happen when the opposite is present
  onClickViewTypeToggleValue = e => {
    let viewType = e.target.value === TripsViewTypes.WEEKLY ? TripsViewTypes.MONTHLY : TripsViewTypes.WEEKLY;
    this.setState({ viewType }, this.getRemoteTripDetails);
  };

  // set data presentation type
  onChangeDataPresentationToggle = e => {
    let dataPresentationType = e.target.value;
    this.setState({ dataPresentationType });
  };

  render () {
    const userCountry = this.props.userCountry;
    const userRole = this.props.userRole;
    const tripWiseEnrolled = this.state.remoteTripDetails && this.state.remoteTripDetails.tripWiseEnrolled;
    const userIsPrimary = isUserPrimary();

    if (this.state.RenderTripWise) {
      return <EnrollTripWise vin={this.props.vin} />;
    } else {
      return this.state.dataLoading ? (
        <div>
          <div>
            {tripWiseEnrolled === true && this.state.path === '#/mqbtrips' && (
              <div align='left' className='rts-back' onClick={() => window.history.back()}>
                <img src={ArrowLeft} className='rts-arrowicon' alt={this.translator.t('trips.left_arrow')} /> &nbsp;
                {this.translator.t('vehicleHealth.sxm.back_label')}
              </div>
            )}
            <h1 className='rts-header'>
              <span className='rts-title1'>{this.translator.t('trips.title_label1')}</span>
              <span className='rts-title2'>{this.translator.t('trips.title_label2')}</span>
            </h1>
          </div>
          <div className='rts-desc'>{this.translator.t('trips.about_desc')}</div>
          <UpdateUBITerms
            displayUBITosModal={this.state.displayUBITosModal}
            toggleUBITosModal={this.toggleUBITosModal}
            ubiManifest={this.state.ubiManifest}
          />
          {this.state.expandDriveView === true && userCountry === 'US' && tripWiseEnrolled === false && (
            <DriveViewFeatures
              viewDriveViewFeatures={this.viewDriveViewFeatures}
              toggleAboutDriveViewModal={this.toggleAboutDriveViewModal}
              remoteTripDetails={this.state.remoteTripDetails}
              callDriveView={this.callDriveView}
              userRole={userRole}
            />
          )}
          <div className='rts-line' />
          <div className='rts-arrow-div'>
            <span
              onClick={() => this.handlePrevNext(true, false)}
              onKeyPress={e => {
                if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                  this.handlePrevNext(true, false);
                }
              }}
              tabIndex='0'
              role='button'
              className='rts-nextprev'
              aria-label={this.translator.t('trips.aria_label_previous_' + this.state.viewType)}
            >
              <img src={ArrowLeft} className='rts-arrowicon' alt='' />
              <span className='rts-nav'>{this.translator.t('trips.previous_label')}</span>
            </span>
            <span className='rts-nextprev-date' aria-live='polite'>
              {this.state.viewType === 'monthly'
                ? getMonthDisplayDate(true, this.state.previousSelected, this.state.nextSelected, this.state.trackDate)
                : getWeekDisplayDate(true, this.state.previousSelected, this.state.nextSelected, this.state.trackDate)}
            </span>
            <span
              onClick={() => this.handlePrevNext(false, true)}
              onKeyPress={e => {
                if (e.key && (e.key === 'Enter' || e.key === ' ')) {
                  this.handlePrevNext(false, true);
                }
              }}
              tabIndex='0'
              role='button'
              className='rts-nextprev'
              aria-label={this.translator.t('trips.aria_label_next_' + this.state.viewType)}
            >
              <span className='rts-nav'>{this.translator.t('trips.next_label')}</span>
              <img src={ArrowRight} className='rts-arrowicon' alt='' />
            </span>
          </div>
          {/*Graph or Table toggle */}
          <div className='toggleContainerLeft'>
            <form>
              <div class='data-view-toggle'>
                <input
                  type='radio'
                  id='selection-graph'
                  name='data-presentation-toggle'
                  value={TripsDataPresentationTypes.GRAPH}
                  checked={this.state.dataPresentationType === TripsDataPresentationTypes.GRAPH}
                  onChange={this.onChangeDataPresentationToggle}
                  onClick={this.onChangeDataPresentationToggle}
                />
                <label
                  for='selection-graph'
                  onClick={() => {
                    this.onChangeDataPresentationToggle({ target: { value: TripsDataPresentationTypes.GRAPH } });
                  }}
                >
                  {this.translator.t('trips.dataview_label_graph')}
                </label>
                <input
                  type='radio'
                  id='selection-table'
                  name='data-presentation-toggle'
                  value={TripsDataPresentationTypes.TABLE}
                  checked={this.state.dataPresentationType === TripsDataPresentationTypes.TABLE}
                  onChange={this.onChangeDataPresentationToggle}
                  onClick={this.onChangeDataPresentationToggle}
                />
                <label
                  for='selection-table'
                  onClick={() => {
                    this.onChangeDataPresentationToggle({ target: { value: TripsDataPresentationTypes.TABLE } });
                  }}
                >
                  {this.translator.t('trips.dataview_label_table')}
                </label>
              </div>
            </form>
          </div>
          {/*Weekly Or Monthly toggle */}
          <div className='toggleContainerRight'>
            <div className='rts-toggle'>
              <Row>
                <form className='trips-data-viewtype'>
                  <input
                    type='radio'
                    id='r-weekly'
                    name='trips-data-viewtype-switch'
                    value={TripsViewTypes.WEEKLY}
                    checked={this.state.viewType === TripsViewTypes.WEEKLY}
                    onChange={this.onChangeViewTypeToggleValue}
                    onClick={this.onClickViewTypeToggleValue}
                  />
                  <label className='left text-nowrap' htmlFor='r-weekly'>
                    {this.translator.t('trips.weekly_label')}
                    <span className='toggleSwitch'>
                      <span className='selectedCircle'> </span>
                    </span>
                  </label>
                  <input
                    type='radio'
                    id='r-monthly'
                    name='trips-data-viewtype-switch'
                    value={TripsViewTypes.MONTHLY}
                    checked={this.state.viewType === TripsViewTypes.MONTHLY}
                    onChange={this.onChangeViewTypeToggleValue}
                    onClick={this.onClickViewTypeToggleValue}
                  />
                  <label className='right text-nowrap' htmlFor='r-monthly'>
                    <span className='toggleSwitch'>
                      <span className='selectedCircle'> </span>
                    </span>
                    {this.translator.t('trips.monthly_label')}
                  </label>
                </form>
              </Row>
            </div>
          </div>

          {this.state.dataPresentationType === TripsDataPresentationTypes.GRAPH && (
            <TripStatsGraph
              viewType={this.state.viewType}
              remoteTripDetails={this.state.remoteTripDetails}
              graphData={this.state.graphData}
              userCountry={userCountry}
              locale={this.props.locale}
            />
          )}

          {this.state.dataPresentationType === TripsDataPresentationTypes.TABLE && (
            <div>
              {this.state.viewType == TripsViewTypes.WEEKLY && (
                <TripStatsTableWeekly
                  remoteTripDetails={this.state.remoteTripDetails}
                  graphData={this.state.graphData}
                  userCountry={userCountry}
                  locale={this.props.locale}
                />
              )}

              {this.state.viewType == TripsViewTypes.MONTHLY && (
                <TripStatsTableMonthly
                  remoteTripDetails={this.state.remoteTripDetails}
                  graphData={this.state.graphData}
                  userCountry={userCountry}
                  locale={this.props.locale}
                />
              )}
            </div>
          )}

          {this.state.collapseDriveView === true && (
            <div className='rts-driveview-collapse'>
              <div className='rts-show' onClick={this.viewDriveViewFeatures}>
                {this.translator.t('trips.show_label')}
                <img src={down} className='down-icon' alt={this.translator.t('trips.down_logo')} />
              </div>

              <Row>
                <div className='rts-get-your-driveview'>
                  {this.translator.t('trips.get_your_driveview_label')}&nbsp;
                  <img
                    src={info}
                    className='driview-feature-icon'
                    alt='Info Logo'
                    onClick={this.toggleAboutDriveViewModal}
                  />
                </div>
              </Row>

              {userIsPrimary && this.state.remoteTripDetails && !this.state.remoteTripDetails.tripWiseEnrolled && (
                <Row>
                  <div className='driview-btn'>
                    <button type='button' className='driview-enroll-btn' onClick={() => this.callDriveView()}>
                      {this.translator.t('trips.enroll_in_driveview')}
                    </button>
                  </div>
                </Row>
              )}
            </div>
          )}
          <TripStatsHistory remoteTripDetails={this.state.remoteTripDetails} userCountry={userCountry} />
          <DriveViewInfoModal
            toggleAboutDriveViewModal={this.toggleAboutDriveViewModal}
            aboutDriveViewModal={this.state.aboutDriveViewModal}
          />
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      );
    }
  }
}

export default TripStatsHeader;
