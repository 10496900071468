import invoke from './ServiceProxy';

const getEnrollmentTosDetails = async () => {
  return invoke({ url: '/account/enrollment/toses' });
};

const saveTosDetails = async data => {
  return invoke({
    method: 'PUT',
    url: `/account/enrollment/toses`,
    data: data
  });
};

export { getEnrollmentTosDetails, saveTosDetails };
