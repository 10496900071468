import React from 'react';
import PropTypes from 'prop-types';
import { getLocaleTranslator, findErrorMessage } from '../../../util/i18n/i18nService';
import { UncontrolledAlert } from 'reactstrap';
import { submitUBIDataShareTos, TOS_STATUS, findApplicableTos, TOS_TYPE } from '../providers/SelfEnrollmentProvider';
import getCwpMeta from '../../../providers/cwpMetaProvider';
import carnet_constants from '../../../constants/carnet_constants';

class UBIDataShare extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      alert: null,
      processing: false,
      tosStatus: props.existingTOS.tosStatus,
      dataShareTosStatus: null,
      dataShareTosVersion: null,
      secureContentUri: null,
      ubiTosUrl: null
    };

    this.translator = getLocaleTranslator();
  }

  static propTypes = {
    existingTOS: PropTypes.object.isRequired,
    afterSubmit: PropTypes.func
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.setState({ processing: true });
    try {
      const response = await findApplicableTos(TOS_TYPE.UBI_DATA_SHARE);
      const cwpMetaData = await getCwpMeta();
      this.setState({
        processing: false,
        dataShareTosVersion: response?.tosVersion,
        secureContentUri: cwpMetaData?.static?.content?.urls?.securecontent,
        ubiTosUrl: response?.tosUrl
      });
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  clearAlerts = () => this.setState({ alert: null });

  getAlerts = () => {
    if (this.state.alert) {
      return (
        <UncontrolledAlert color='danger' toggle={this.clearAlerts}>
          {this.state.alert}
        </UncontrolledAlert>
      );
    }

    return null;
  };

  submitTos = async () => {
    this.setState({ processing: true, alert: null });
    try {
      const response = await submitUBIDataShareTos(this.state.dataShareTosStatus, this.state.dataShareTosVersion);
      this.setState({ processing: false });
      this.props.afterSubmit(this.state.tosStatus, response);
    } catch (error) {
      this.setState({ processing: false, alert: findErrorMessage(error, this.translator) });
    }
  };

  render () {
    return (
      <>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-10 text-left mx-auto mb-5'>
            <h1 className='text-left float-left mr-3'>
              {this.translator.t('tos_ubi.get_your_driveview')}
              <sup>{this.translator.t('tos_ubi.tm')}</sup> {this.translator.t('tos_ubi.score')}
            </h1>
            <h3 className='font-weight-normal'>{this.translator.t('tos_ubi.daily_weekly_monthly')}</h3>
            {this.getAlerts()}
            <br />
            <div className='row m-0 p-0'>
              <div className='col-md-3 px-0'>
                <div className='ubiPhoneScreen'></div>
              </div>
              <div className='col-md-8'>
                <h5 className='mt-5'>{this.translator.t('tos_ubi.learn_about_your_driving_behavior')}</h5>
                <span className='small'>{this.translator.t('tos_ubi.learn_about_your_driving_behavior_desc')}</span>
                <div className='row mx-0 my-md-3'>
                  <div className='col-md-6 p-0'>
                    <h6 className='leftIcon iconBreak'>{this.translator.t('tos_ubi.hard_braking')}</h6>
                    <spam className='small'>{this.translator.t('tos_ubi.hard_braking_desc')}</spam>
                    <br />
                    <h6 className='leftIcon iconSpeed'>{this.translator.t('tos_ubi.high_speed_driving')}</h6>
                    <spam className='small'>{this.translator.t('tos_ubi.high_speed_driving_desc')}</spam>
                  </div>
                  <div className='col-md-6 p-0'>
                    <h6 className='leftIcon iconNighttime'>{this.translator.t('tos_ubi.nighttime_driving')}</h6>
                    <spam className='small'>{this.translator.t('tos_ubi.nighttime_driving_desc')}</spam>
                    <br />
                    <h6 className='leftIcon iconIdle'>{this.translator.t('tos_ubi.idle_time')}</h6>
                    <spam className='small'>{this.translator.t('tos_ubi.idle_time_desc')}</spam>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-1'></div>
        </div>
        <div className='row ubiHilights m-0'>
          <div className='col-md-1'></div>
          <div className='col-md-10 text-left mx-auto my-5'>
            <div className='row m-0 p-0'>
              <div className='col-md-4 px-0'></div>
              <div className='col-md-8'>
                <div className='col-md-8 m-0 p-0'>
                  <h2 className='font-weight-normal my-0'>{this.translator.t('tos_ubi.why_should_share_my')}</h2>
                  <span className='small'>{this.translator.t('tos_ubi.driveview_calculates_cumulative')}</span>
                  <br />
                  <div className='small mt-4'>{this.translator.t('tos_ubi.you_can_still_use_driveview')}</div>
                  <h6 className='font-weight-normal mt-5 ubiTerms'>
                    <a
                      href={`${this.state.secureContentUri}${this.state.ubiTosUrl}`}
                      target='blank'
                      className='driviewTermsText'
                    >
                      {this.translator.t('tos_ubi.driview_terms')} &rarr;
                    </a>
                  </h6>
                  <div className='discountedInsuranceRates mt-4'>
                    {this.translator.t('tos_ubi.discounted_insurance_rates_may_not_be_available_in_all_states')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-1'></div>
        </div>
        <div className='col-md-8 text-left mx-auto my-5'>
          <h5>{this.translator.t('tos_ubi.start_sharing_your_driving_data_today')}</h5>
          <span>
            {this.translator.t('tos_ubi.you_can_choose_to_share_driving_data_from_your_vehicle')}
            <a href={carnet_constants.DRIVEVIEW_PRIVACY_URL} target='blank' className='carnetLink'>
              {this.translator.t('tos_ubi.here')}
            </a>
            {this.translator.t('tos_ubi.insurance_related_companies')}
            <a href={carnet_constants.DRIVEVIEW_PRIVACY_URL} target='blank' className='carnetLink'>
              {this.translator.t('tos_ubi.here')}.
            </a>
            {this.translator.t('tos_ubi.learn_more_about_privacy')}
          </span>
          <br />
          <br />
          <span
            className={this.state.dataShareTosStatus === TOS_STATUS.ACCEPT ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
            onClick={e => this.setState({ dataShareTosStatus: TOS_STATUS.ACCEPT })}
          >
            <span className='radioDescText'>{this.translator.t('tos_ubi.agree_to_share_driving_data')}</span>
          </span>
          <br />
          <br />
          <span
            className={this.state.dataShareTosStatus === TOS_STATUS.DECLINE ? 'cwpRadio cwpRadioOn' : 'cwpRadio'}
            onClick={e => this.setState({ dataShareTosStatus: TOS_STATUS.DECLINE })}
          >
            <span className='radioDescText'>
              {this.translator.t('tos_ubi.no_do_not_enroll_me_thrid_party_sharing')}
            </span>
          </span>
        </div>
        <div className='tos-cont-btn-wrapper'>
          <button
            className='cwp mb-5'
            onClick={this.submitTos}
            disabled={this.state.processing || !this.state.dataShareTosStatus}
          >
            {this.translator.t('tos_ubi.continue')}
          </button>
        </div>
      </>
    );
  }
}

export default UBIDataShare;
