import React, { useState, useEffect } from 'react';
import { getLocaleTranslator } from '../../../util/i18n/i18nService';
import Modal from 'react-bootstrap/Modal';
import './driveView.scss';
import { getVehicleContext } from '../../../providers/historyProvider';
import moment from 'moment';
import { saveTosDetails } from '../providers/EnrollmentTosProvider';
import carnet_constants from '../../../constants/carnet_constants';
import getCwpMeta from '../../../providers/cwpMetaProvider';

const UpdateUBITerms = props => {
  const translator = getLocaleTranslator();
  const [userId, setUserId] = useState('');
  const [vehicleId, setVehicleId] = useState('');
  const [ubiTosStatus, setUbiTosStatus] = useState(null);
  const [secureContentUrl, setSecureContentUrl] = useState(null);

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    try {
      const vehicleContext = await getVehicleContext();
      const cwpMetaData = await getCwpMeta();
      setVehicleId(vehicleContext?.vehicleId);
      setUserId(vehicleContext?.userId);
      setSecureContentUrl(cwpMetaData?.static?.content?.urls?.securecontent);
    } catch (err) {
      console.error('Error fetching vehicle details:', err);
    }
  };

  const buildUbiTos = () => ({
    vehicleId,
    userId,
    toses: [
      {
        tosStatus: ubiTosStatus,
        tosTimeStamp: moment().valueOf(),
        tosVersion: props.ubiManifest?.tosVersion,
        tosType: carnet_constants.UBI
      }
    ]
  });

  const submitUBITos = async () => {
    const ubiTos = buildUbiTos();
    try {
      const response = await saveTosDetails(ubiTos);
      if (response) {
        props.toggleUBITosModal();
      }
    } catch (err) {
      console.error('Error while submitting UBITos:', err);
    }
  };

  return (
    <Modal show={props.displayUBITosModal} onHide={props.toggleUBITosModal} centered backdrop='static'>
      <Modal.Body>
        <div className='ubi-terms'>
          <div className='we-have-updated'>{translator.t('ubiTosUpdate.weHaveUpdatedTos')}</div>
          <div className='we-are-always'>{translator.t('ubiTosUpdate.weAreAlwaysWorking')}</div>
          <div className='col-md-10 text-left mx-auto my-3'>
            <span
              className={
                ubiTosStatus === carnet_constants.TOS_STATUS_ACCEPT
                  ? 'cwpRadio cwpRadioOn radio-btn-text'
                  : 'cwpRadio radio-btn-text'
              }
              onClick={() => setUbiTosStatus(carnet_constants.TOS_STATUS_ACCEPT)}
            >
              {translator.t('ubiTosUpdate.acceptThe')}
              <a
                href={`${secureContentUrl}${props.ubiManifest?.tosUrl}`}
                target='_blank'
                rel='noopener noreferrer'
                className='radio-btn-text tos-text'
              >
                {translator.t('ubiTosUpdate.driveViewTos')}
              </a>
            </span>
          </div>

          <div className='grid-center'>
            <button type='button' className='cwp continue-btn' onClick={submitUBITos} disabled={!ubiTosStatus}>
              {translator.t('ubiTosUpdate.continue')}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateUBITerms;
