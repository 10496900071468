import {
  GET_CARNET_CUSTOMER_STATIC_DATA,
  GET_CARNET_CUSTOMER_STATIC_DATA_SUCCESS,
  GET_CARNET_CUSTOMER_STATIC_DATA_FAILURE
} from '../../actions/types';

const initialState = {
  states: null,
  countries: null,
  securityQuestions: null
};

const carnetCustomerStaticDataReducer = (state = initialState, action = { payload: {} }) => {
  const { type, payload } = action;
  let newState = { ...state };

  switch (type) {
    case GET_CARNET_CUSTOMER_STATIC_DATA:
      return initialState;

    case GET_CARNET_CUSTOMER_STATIC_DATA_SUCCESS:
      let responseData = payload || {};
      newState.states = responseData[0]?.states || null;
      newState.countries = responseData[1]?.countries || null;
      newState.securityQuestions = responseData[2]?.securityQuestions || null;
      return newState;

    case GET_CARNET_CUSTOMER_STATIC_DATA_FAILURE:
      newState.states = null;
      newState.countries = null;
      newState.securityQuestions = null;
      return newState;

    default:
      return newState;
  }
};

export default carnetCustomerStaticDataReducer;
