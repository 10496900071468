import { isNullOrEmptyObject } from '../../../util/utilityMethods';
import { CATEGORY_TYPES } from '../buyPlans/buyPlansUtil';

const SubCategory = Object.freeze({
  TRIAL_IN_CURRENT_PLAN: 'TRIAL_IN_CURRENT_PLAN',
  ALEXA_WEBAPP: 'ALEXA_WEBAPP',
  PTP: 'ptp',
  UBI: 'UBI',
  TRIAL: 'TRIAL',
  GARAGECONTROL_TRIAL: 'GARAGECONTROL_TRIAL',
  GARAGECONTROL: 'GARAGECONTROL',
  PREPAID: 'PREPAID',
  POSTPAID: 'POSTPAID',
  SAFETY_FREE: 'SAFETY_FREE'
});

const PlanStatus = Object.freeze({
  SCHEDULED_FOR_PURCHASE: 'SCHEDULED_FOR_PURCHASE',
  PENDING: 'PENDING',
  PURCHASE: 'PURCHASE',
  PURCHASED: 'PURCHASED',
  ACTIVE: 'ACTIVE'
});

const PlanType = Object.freeze({
  DATA: 'DATA',
  SERVICE: 'SERVICE'
});

const PlanTitle = Object.freeze({
  HOTSPOT_UNLIMITED_WIFI: 'HOTSPOT - UNLIMITED WI-FI'
});

const MarketingConfigurationId = Object.freeze({
  SKU_PTP: 'SKU_PTP'
});

const MnoPorvider = Object.freeze({
  TMO: 'TMO',
  VERIZON: 'Verizon',
  ROGERS: 'ROGERS'
});

const PricingType = Object.freeze({
  RECURRING: 'RECURRING',
  ONE_TIME: 'ONE_TIME',
  RATE_PLAN_CHANGE: 'RATE_PLAN_CHANGE',
  PAY_PER_USE: 'PAY_PER_USE'
});

const TimePeriodType = Object.freeze({
  YEARLY: 'YEARLY',
  MONTHLY: 'MONTHLY'
});

const EndCondition = Object.freeze({
  FIXED_TIME: 'FIXED_TIME',
  SUBSCRIPTION_END: 'SUBSCRIPTION_END'
});

const UpToPeriodType = Object.freeze({
  DAYS: 'DAYS',
  MONTHS: 'MONTHS',
  YEARS: 'YEARS'
});

const PurchaseBlockReason = Object.freeze({
  TRIAL_IN_CURRENT_PLAN: 'TRIAL_IN_CURRENT_PLAN',
  ACTIVATE_TRIAL_FIRST: 'ACTIVATE_TRIAL_FIRST'
});

const ChamberlainSubCategoryList = Object.freeze([SubCategory.GARAGECONTROL, SubCategory.GARAGECONTROL_TRIAL]);
const ChamberlainLinkingStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
});

const ServiceResponseStatus = Object.freeze({
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
});

const pickByStatus = (status, active = () => null, pending = () => null, expired = () => null) => {
  switch (status) {
    case PlanStatus.ACTIVE:
      return active();

    case PlanStatus.PURCHASE:
    case PlanStatus.SCHEDULED_FOR_PURCHASE:
    case PlanStatus.PURCHASED:
    case PlanStatus.PENDING:
      return pending();

    default:
      return expired();
  }
};

const findFirstFromList = (plans, filters) => {
  if (!Array.isArray(plans) || plans.length < 1 || isNullOrEmptyObject(filters)) {
    return null;
  }

  const keys = Object.keys(filters);
  return plans.find(plan => keys.every(key => `${plan[key]}`.toLowerCase() === `${filters[key]}`.toLowerCase()));
};

const findArticleDisclaimerText = (configuration = {}) => {
  const { articleDisclaimerFlag, articleDisclaimerNumber } = configuration;

  if (articleDisclaimerFlag === true) {
    return findDisclaimerByNumber(configuration, articleDisclaimerNumber);
  }

  return null;
};

const findDisclaimerByNumber = (configuration = {}, disclaimerNumber) => {
  const disclaimerTextList = configuration?.disclaimerTextList;

  if (Array.isArray(disclaimerTextList)) {
    return disclaimerTextList.find(dt => dt?.disclaimerNumber === disclaimerNumber)?.disclaimerText;
  } else {
    return null;
  }
};

const findDataTrialPlan = plans => {
  if (Array.isArray(plans)) {
    return plans.find(
      plan =>
        plan?.category?.toUpperCase() === CATEGORY_TYPES.DATA && plan?.subCategory?.toUpperCase() === SubCategory.TRIAL
    );
  }

  return null;
};

const hasActiveDataPlan = plans => {
  return (
    Array.isArray(plans) &&
    plans.length > 0 &&
    plans.some(plan => plan.planType === PlanType.DATA && plan.status === PlanStatus.ACTIVE)
  );
};

const isDescriptionForActivePTPorHotSpotUnlimitedPlan = (plan, marketingConfiguration) => {
  return (
    PlanStatus.ACTIVE === plan?.status?.toUpperCase() &&
    (MarketingConfigurationId.SKU_PTP === plan?.marketingConfigurationId?.toUpperCase() ||
      PlanTitle.HOTSPOT_UNLIMITED_WIFI === marketingConfiguration?.title?.toUpperCase())
  );
};

const getPriceDescription = (translator, pricingOption) => {
  const { pricingType, timePeriodType, uptoPeriod, uptoPeriodType, endCondition } = pricingOption;

  if (endCondition !== EndCondition.FIXED_TIME) {
    return '';
  }

  switch (pricingType) {
    case PricingType.ONE_TIME:
      return translator.t(
        `buyplans.price-description.one_time.fixed_time_${timePeriodType || uptoPeriodType}`.toLowerCase(),
        {
          uptoPeriod
        }
      );

    case PricingType.RECURRING:
      return translator.t(
        `buyplans.price-description.recurring.fixed_time_${timePeriodType || uptoPeriodType}_${
          Number.isInteger(uptoPeriod) && uptoPeriod > 0 ? '1' : '0'
        }`.toLowerCase(),
        { uptoPeriod }
      );

    case PricingType.RATE_PLAN_CHANGE:
    case PricingType.PAY_PER_USE:
    default:
      return '';
  }
};

const isNotPurchasableOrRestricted = plan => {
  const { purchaseable, subCategory } = plan || {};

  if (!purchaseable) {
    return false;
  }

  switch (subCategory?.toUpperCase()) {
    case SubCategory.TRIAL:
    case SubCategory.PTP:
    case SubCategory.UBI:
    case SubCategory.ALEXA_WEBAPP:
    case SubCategory.SAFETY_FREE:
      return false;

    default:
      return true;
  }
};

const maskPaymentCardNumber = (carrierNumber, unmaskDigitCount) => {
  if (typeof carrierNumber === 'string' && carrierNumber.length > 4) {
    return Array.from(carrierNumber)
      .fill('*', 0, carrierNumber.length - unmaskDigitCount)
      .join('');
  }

  return carrierNumber;
};

const getStoredPayments = storedPaymentOptions => {
  console.log('storedPaymentOptions :::::', JSON.stringify(storedPaymentOptions, null, 1));
  let storedPayments = [];
  storedPaymentOptions.forEach((storedPaymentOption, index) => {
    storedPayments.push({
      name: storedPaymentOption?.name || storedPaymentOption?.Card?.Brand,
      carrierNumber:
        '************' + storedPaymentOption?.Card?.Last4 || storedPaymentOption?.carrierNumber?.substr(-4),
      reference: storedPaymentOption?.reference || storedPaymentOption?.Id,
      inputId: 'storedPaymentOption' + index,
      showCVVId: index,
      showCVV: false
    });
  });
  console.log('storedPayments :::::', JSON.stringify(storedPayments, null, 1));
  return storedPayments;
};

export {
  PlanTitle,
  PlanType,
  PlanStatus,
  SubCategory,
  MarketingConfigurationId,
  MnoPorvider,
  PurchaseBlockReason,
  ChamberlainSubCategoryList,
  ChamberlainLinkingStatus,
  ServiceResponseStatus,
  findFirstFromList,
  pickByStatus,
  findArticleDisclaimerText,
  findDisclaimerByNumber,
  isDescriptionForActivePTPorHotSpotUnlimitedPlan,
  findDataTrialPlan,
  hasActiveDataPlan,
  getPriceDescription,
  isNotPurchasableOrRestricted,
  maskPaymentCardNumber,
  getStoredPayments
};
