import {
  GET_USER_SECURITY_QUESTIONS,
  GET_USER_SECURITY_QUESTIONS_SUCCESS,
  GET_USER_SECURITY_QUESTIONS_FAILURE
} from '../../actions/types';

const initialState = {
  customerData: null,
  dataLoading: false
};

const userSecurityQuestionsReducer = (state = initialState, action = { payload: {} }) => {
  const { type, payload } = action;
  let responseData = payload || {};
  let newState = { ...state };
  switch (type) {
    case GET_USER_SECURITY_QUESTIONS:
      newState.dataLoading = true;
      return newState;
    case GET_USER_SECURITY_QUESTIONS_SUCCESS:
      const rcvdData = responseData?.data?.data || null;
      return {
        ...state,
        data: rcvdData,
        dataLoading: false
      };
    case GET_USER_SECURITY_QUESTIONS_FAILURE:
      return {
        ...state,
        data: null,
        dataLoading: false
      };
    default:
      return state;
  }
};

export default userSecurityQuestionsReducer;
