import { getLocaleSync } from '../../../providers/languageProvider';
import invoke from './ServiceProxy';

const TOS_TYPE = Object.freeze({
  CARNET: 'CARNET',
  UBI: 'UBI',
  PTP: 'PTP',
  DATA_TRIAL: 'DATA_TRIAL',
  UBI_DATA_SHARE: 'UBI_DATA_SHARE'
});

const TOS_STATUS = Object.freeze({
  ACCEPT: 'Accept',
  DECLINE: 'Decline'
});

const TSP_PROVIDER = Object.freeze({
  ATC: 'ATC',
  VZT: 'VZT',
  WCT: 'WCT'
});

const ENROLLMENT_PROCEDURE = Object.freeze({
  NOT_APPLICABLE: 'NOT_APPLICABLE',
  QR_SCAN: 'QR_SCAN',
  LOGIN_TO_VEHICLE: 'LOGIN_TO_VEHICLE',
  REG_CODE: 'REG_CODE',
  RDR: 'RDR',
  NKEY_FOB: 'NKEY_FOB',
  VZT: 'VZT',
  NOT_ALLOWED_DUE_FLEET_OWNERSHIP: 'NOT_ALLOWED_DUE_FLEET_OWNERSHIP',
  THIS_USER_IS_ALREADY_ENROLLED: 'THIS_USER_IS_ALREADY_ENROLLED'
});

const PHONE_TYPE = Object.freeze({
  HOME_PHONE_NUM: 'HOME_PHONE_NUM',
  BUS_PHONE_NUM: 'BUS_PHONE_NUM',
  CELL_PHONE_NUM: 'CELL_PHONE_NUM'
});

const findVehicle = async vin => {
  return await invoke({ url: '/findVehicle', method: 'POST', data: { vin } });
};

const findEnrollmentProcedure = async () => {
  return await invoke({ url: '/findEnrollmentProcedure', method: 'POST' });
};

const findApplicableTos = async tosType => {
  return await invoke({ url: '/findApplicableTos', method: 'POST', data: { tosType, locale: getLocaleSync().locale } });
};

const submitTosCarnetVZT = async (tosVersion, tosStatus, tspAccountNum, tspPIN) => {
  return await invoke({
    url: '/selfEnrollment/submitTosCarnet/vzt',
    method: 'POST',
    data: { tosVersion, tosStatus, tspAccountNum, tspPIN }
  });
};

const submitTosCarnet = async (tosVersion, tosStatus) => {
  return await invoke({ url: '/selfEnrollment/submitTosCarnet', method: 'POST', data: { tosVersion, tosStatus } });
};

const submitTosCarnetUBI = async (tosVersion, tosStatus) => {
  const data = {
    tosVersion,
    tosStatus
  };

  return await invoke({ url: '/submitTosUBI', method: 'POST', data });
};

const submitTosDataTrial = async (tosStatus, tosVersion) => {
  return await invoke({
    url: '/submitTosDataTrial',
    method: 'POST',
    data: { tosStatus, tosVersion }
  });
};

const submitUBIDataShareTos = async (tosStatus, tosVersion) => {
  return await invoke({
    url: '/submitUBIDataShare',
    method: 'POST',
    data: { tosStatus, tosVersion }
  });
};

const fetchUserProfile = async () => {
  return await invoke({ url: '/fetchUserProfile', method: 'POST' });
};

const saveUserProfile = async data => {
  return await invoke({ url: '/saveUserProfile', method: 'POST', data });
};

const getCarnetMarketingStaticInfo = async () => {
  return await invoke({ url: '/static/marketingdata' });
};

export {
  TOS_TYPE,
  TOS_STATUS,
  TSP_PROVIDER,
  ENROLLMENT_PROCEDURE,
  PHONE_TYPE,
  findVehicle,
  findEnrollmentProcedure,
  findApplicableTos,
  submitTosCarnet,
  submitTosCarnetUBI,
  submitTosDataTrial,
  submitTosCarnetVZT,
  fetchUserProfile,
  saveUserProfile,
  getCarnetMarketingStaticInfo,
  submitUBIDataShareTos
};
