import { createLogic } from 'redux-logic';
import axios from 'axios';
import { GET_CAR_NICK_NAME, GET_CAR_NICK_NAME_SUCCESS, GET_CAR_NICK_NAME_FAILURE } from '../../actions/types';

const dataProvider = axios;

const requestConfig = {
  method: 'GET',
  url: `/carnickname`,
  headers: {
    accept: 'application/json'
  }
};

const getCarNickNameLogic = createLogic({
  type: GET_CAR_NICK_NAME,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: GET_CAR_NICK_NAME_SUCCESS,
    failType: GET_CAR_NICK_NAME_FAILURE
  },

  process ({ action }, dispatch, done) {
    console.log('Processing action in getCarNickNameLogic:', action);
    return dataProvider(requestConfig)
      .then(response => {
        console.log('got response for getCarNickName GET request >>> ');
        console.log(JSON.stringify(response.data, null, 1));
        return response.data;
      })
      .catch(error => {
        console.error('Error in getCarNickNameLogic:', error);
        dispatch({ type: GET_CAR_NICK_NAME_FAILURE, payload: error, carNickNameLoadError: true });
      })
      .finally(done);
  }
});

export default [getCarNickNameLogic];
